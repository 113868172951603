import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { __product } from './__product'
import { Titles } from '../../../layouts/Titles'
import i from '../../../translations/i'
import { Row, Col, Table, Space, Button, Input, Segmented, Select } from "antd";
import { IProduct } from '../../../components/list/types'
import { SettingOutlined } from '@ant-design/icons'
import { ProductsList } from '../../../components/list/ProductsList'
import { runInAction } from 'mobx'
import __multiple from '../../../helpers/multiple_actions/__multiple'
import { WarehousesController } from "../../settings/warehouses/warehouses.controller";
import __catalog from "../__catalog";

const Warehouses = new WarehousesController()

export const ProductList = observer(() => {

    useEffect(() => {
      Warehouses.get(1000000)
        __multiple.setActions(__product.getActions())
        __product.getList()
    }, [])

    return(
        <>
            <Titles
                title={i.t('search:searchProducts')}
                breadcrumb={[
                    { title: i.t('search:searchProducts') }
                ]}
                buttons={[
                    {title: i.t('categories:createProduct'), href: `/shop/catalog/product/0`, type: `primary`}
                ]}
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col span={12}>
                        <Segmented
                          value={__product.filter.segmented}
                          options={[
                            { label: i.t('def:All'), value: 'all' },
                            { label: i.t('def:Active'), value: "active" },
                            { label: i.t('def:Hidden'), value: "hidden" },
                          ]}
                          onChange={(e) => {
                            __product.filter.segmented = e
                            __product.pagination.page = 1
                            __product.getList()
                            __multiple.setActions(__product.getActions())
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          placeholder={i.t('def:Select warehouse')}
                          value={__product.filter.warehouse_id}
                          options={[
                            { label: i.t('def:Select warehouse'), value: null },
                            ...Warehouses.list.map((el: any) => ({
                              label: el.name,
                              value: el.id
                            }))
                          ]}
                          onChange={(e) => {
                            __product.filter.warehouse_id = e
                            __product.pagination.page = 1
                            __product.getList()
                            __multiple.setActions(__product.getActions())
                          }}
                          style={{ width: `100%` }}
                        />
                      </Col>
                        <Col span={6}>
                            <Input
                                placeholder={i.t('search:changeName')}
                                value={__product.filter_list.name ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.name = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="ID"
                                value={__product.filter_list.id ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.id = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="ID CRM"
                                value={__product.filter_list.id_crm ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.id_crm = e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder={i.t('def:vendor')}
                                value={__product.filter_list.vendor_code ?? ''}
                                onChange={(e) => runInAction(() => __product.filter_list.vendor_code = e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <ProductsList
                        products={__product.list}
                        pagination={{
                            position: ['bottomLeft'],
                            hideOnSinglePage: true,
                            current: __product.pagination.page,
                            pageSize: __product.pagination.limit,
                            total: __product.pagination.total,
                            showSizeChanger: false,
                            onChange: (e: number) => __product.setPage(e)
                        }}
                        actions={{
                            title: i.t('orders:actions'),
                            key: 'action',
                            align: 'right',
                            render: (record: IProduct) => (
                                <Space>
                                    <Button
                                        href={`/shop/catalog/product/${record.id}`}
                                        target="_blank"
                                        type="dashed"
                                        icon={<SettingOutlined />}
                                    />
                                </Space>
                            )
                        }}
                        setActive={(productID: number, view: boolean) => __product.setActive(productID, view)}
                    />
                </Col>
            </Row>
        </>
    )
})