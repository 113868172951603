import { storeSession, storeLocal } from './stores'
import { openApi, request } from './request'
import userController from '../front/user/controllers/user.controller'

export const getUnits = async() => {
    openApi('get', `/unit`)
        .then(result => {
            storeSession.set('project_units', result.data ?? [])
        })
}

export const getProjectByBundle = async () => {
    const bundle = await parseProject('bandl')

    request('get', `/admin/get/${bundle}`)
        .then(result => {
            userController.encrypt('project', result)
        })
        .catch(err => {

        })
}

export const parseProject = async (title: string = '') => {
    const project = await userController.decrypt('project')
    if(project) {
        if(title !== '') {
            return project[title]
        } else {
            return project
        }
    }
}

export const parseProjectElement = (title: string = '', change: any) => {
    userController.decrypt('project').then(project => {
        change(project[title])
    })
}

export const returnParseProjectElement = (title: string = '', project: boolean = false) => {
    return userController.decryptName(title, project)
}

export const parseUser = async () => {
    const user = await userController.decrypt('user')
    if(user) {
        return user
    }
}

export const customToFixed = (num: any, digits: any) => {
    // Используем toFixed для округления до нужного количества знаков
    let formatted = num.toFixed(digits);

    // Удаляем лишние нули и точку, если число целое
    return parseFloat(formatted).toString();
}