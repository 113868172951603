import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import controller from "../../news.controller";
import i from "../../../../translations/i";
import { Button, Col, Input, Row, Upload } from "antd"
import { runInAction } from "mobx"
import { PlusOutlined } from "@ant-design/icons"
import { deleteFile } from '../../../../helpers/files.controller'


export const NewsModuleShort = observer(() => {

  return(
    <>
      <Col span={24}>
        {controller.item.image
          ? <Row gutter={[10, 10]}>
            <Col xs={24}>
              <img src={controller.item.image.base + controller.item.image.uri} alt='' style={{maxWidth: '250px'}}/>
            </Col>
            <Col xs={24}>
              <Button
                onClick={() => {
                  deleteFile(controller.item.image.id)
                  controller.item.image = null
                }}
              >
                {i.t('def:deletePicture')}
              </Button>
            </Col>
          </Row>
          : <></>
        }


        <Upload
          beforeUpload={(info) => {
            controller.upload(info)
            return false
          }}
          listType="picture-card"
          className="photo-uploader"
          maxCount={1}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
          </div>
        </Upload>
      </Col>
      <Col span={24}>
        <label>{i.t('def:Short text')}</label>
        <Input.TextArea
          placeholder={i.t('def:Short text')}
          value={controller.item.short_description}
          onChange={(e) => runInAction(() => controller.item.short_description = e.target.value)}
        />
      </Col>
    </>
  )
})