import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Button, Space } from "antd";
import { BoldOutlined, ItalicOutlined, StrikethroughOutlined } from "@ant-design/icons";
import React from "react";
import 'react-quill/dist/quill.snow.css';
import { observer } from "mobx-react-lite";

const TextEditorFunctions = () => {
  const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return(
    <>
      <div className="control-group">
        <div className="button-group">
          <Space>
            <Button
              onClick={() => editor.chain().focus().toggleBold().run()}
              size="small"
              type="dashed"
              icon={<BoldOutlined />}
              htmlType="button"
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleBold()
                  .run()
              }
              className={editor.isActive('bold') ? 'is-active' : ''}
            />
            <Button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              size="small"
              type="dashed"
              icon={<ItalicOutlined />}
              htmlType="button"
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleItalic()
                  .run()
              }
              className={editor.isActive('italic') ? 'is-active' : ''}
            />
            <Button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              size="small"
              type="dashed"
              icon={<StrikethroughOutlined />}
              htmlType="button"
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleStrike()
                  .run()
              }
              className={editor.isActive('strike') ? 'is-active' : ''}
            />
          </Space>
        </div>
      </div>
    </>
  )
}

export const TextEditor = ({ content, returned }: any) => {
  console.log(content)

  const extensions = [

    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),
  ]

  return(
    <>
      <EditorProvider
        slotBefore={<TextEditorFunctions />}
        extensions={extensions}
        content={content ?? ''}
        onUpdate={({ editor }: any) => returned(editor.getHTML())}
      />
    </>
  )
}