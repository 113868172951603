import { makeObservable, observable, toJS } from "mobx";
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { openApi } from "../../../helpers/request";

class NewsPropsController {

  list: any[] = []
  item: any = {
    id: 0,
    name: '',
    type: 'checkbox',
    values: []
  }

  pagination = {
    total: 0,
    limit: 10,
    page: 1,
    offset: 0
  }

  file: any = null

  constructor() {
    makeObservable(this, {
      list: observable,
      item: observable,
      pagination: observable,
    })
  }

  getList(limit: number | null = null, values: boolean = false) {
    limit = limit ? limit : this.pagination.limit
    let path = `/news_props?limit=${limit}&page=${this.pagination.page}`

    if(values) path += `&with[]=values`

    openApi('get', path)
      .then(result => {
        console.log(result.data)
        this.list = result.data
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  getOne(id: any) {
    if(id > 0)
      openApi('get', `/news_props/${id}?with[]=values`)
        .then(result => {
          console.log(result.data)
          this.item = result.data
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  delete(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/news_props/${id}`)
        .then(result => {
          this.getList()
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    data.append('name', this.item.name ? `${this.item.name}` : '')
    data.append('type', this.item.type)

    this.item.values.map((value: any, index: number) => {
      if(value.id && value.id > 0) data.append(`values[${index}][id]`, value.id)
      data.append(`values[${index}][value]`, value.value)
    })

    openApi('post', `/news_props/${this.item.id}`, data)
      .then(result => {
        if(this.item.id && this.item.id > 0)
          this.item = result.data
        else window.location.href = `/news_props`
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  addPropertyValue() {
    this.item.values.push({
      id: 0,
      value: ''
    })
  }

  removePropertyValue(index: number) {
    let array = toJS(this.item.values)
    array.splice(index, 1)
    this.item.values = array
  }

}

export default new NewsPropsController()