import { observer } from 'mobx-react-lite'
import { Row, Col } from 'antd'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import { Editor } from 'primereact/editor'
import { TextEditor } from "../../../../helpers/TextEditor";
import React from "react";

export const ProductText = observer(() => {

  return(
    <>
      <Row gutter={[20, 20]} align="bottom">
        <Col span={24}>
          <label className="formLabel">{i.t('def:Text')}</label>

          <TextEditor
            content={__product.item.text}
            returned={(e: any) => __product.item.text = e}
          />
        </Col>
      </Row>
    </>
  )
})