import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import controller from "../../news.controller";
import i from "../../../../translations/i";
import { Button, Col, Input, Row, Upload } from "antd"
import news_props_controller from '../../props/news.props.controller'


export const NewsModuleProps = observer(() => {

  useEffect(() => {
    news_props_controller.getList(null, true)
  }, [])

  return(
    <>
      <Col span={24}>
        {news_props_controller.list.map((property: any, index: number) => {
          return (<React.Fragment key={index}>
            <p className="product-filters-title">{property.name}</p>
            <div className="product-filters-elements">
              {property.values.map((v: any, index: number) =>
                <span
                  key={index}
                  onClick={() => controller.selectProperty(v.id)}
                  className={controller.selectedProperties.find((elem: any) => elem === v.id) ? 'active' : ''}
                >{v.value}</span>
              )}
            </div>
          </React.Fragment>)
        })}

      </Col>
    </>
  )
})