import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Button, Col, Input, Row, Select, Space, Switch, Table, Tooltip, Upload } from "antd";
import { DeleteOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import React, { FormEvent, useEffect } from "react";
import controller from "./news.props.controller";
import { useParams } from "react-router-dom";
import { runInAction } from "mobx";
import { SketchPicker } from "react-color";

export const NewsProps = observer(() => {

  useEffect(() => {
    return () => {
      controller.getList()
    }
  }, [])

  const columns = [
    {
      title: i.t('def:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('def:actions.actions'),
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title={i.t('def:actions.edit')}>
            <Button type="dashed" shape="circle" href={`/news_props/${record.id}`} icon={<SettingOutlined />} />
          </Tooltip>

          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.delete(record.id)}
          />
        </Space>
      ),
    },
  ]

  return(
    <>
      <Titles
        title={i.t('def:News props')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/news_props/0` }
        ]}
        breadcrumb={[
          {title: i.t('def:News Types')},
        ]}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})

export const NewsProp = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    return () => {
      controller.getOne(id)
    }
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/news_props`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return(
    <>
      <form onSubmit={(e: FormEvent) => controller.save(e)}>
        <Titles
          title={i.t('def:News prop')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col xs={24} md={24}>
            <label>{i.t('settings:cities.form.name')}</label>
            <Input
              name="title"
              placeholder={i.t('settings:cities.form.name')}
              value={controller.item.name}
              onChange={(e) => runInAction(() => controller.item.name = e.target.value)}
            />
          </Col>
          <Col span={24}>
            <Select
              value={controller.item.type}
              style={{ width: 100 + '%' }}
              onChange={e => controller.item.type = e}
              options={[
                {label: i.t('props:example.checkbox'), value: 'checkbox'},
                {label: i.t('props:example.radio'), value: null}
              ]}
            />
          </Col>
          <Col span={24}>
            <label className="formLabel">{i.t('def:Values')}</label>
            <Button
              type="primary"
              onClick={() => controller.addPropertyValue()}
            >{i.t('def:actions.add')}</Button>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              {controller.item.values.map((value: any, index: number) => {

                return (<>
                  <Col span={20}>
                    <Input
                      value={controller.item.values[index]['value']}
                      onChange={ (e) => controller.item.values[index]['value'] = e.target.value }
                    />
                  </Col>
                  <Col span={4}>
                    <Button
                      type="dashed"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => controller.removePropertyValue(index)}
                    />
                  </Col>
                </>)
              })}
            </Row>
          </Col>
        </Row>
      </form>
    </>
  )
})