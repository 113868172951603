import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { notification } from 'antd'
import __multiple from '../../../helpers/multiple_actions/__multiple'

interface FormInterface {
    id: number
    enabled: boolean
    title: string
    description: string
    webhook: boolean
    elements: any
    picture: any
    show_in_list: boolean
    type_view: string
    success_title: string
    success_description: string
}

interface ElementInterface {
    id: number
    enabled: boolean
    is_filled: boolean
    feedback_id: number
    type: string
    required: boolean
    label: string
    placeholder: string
    is_hidden: boolean
    multiple: boolean
    min: number
    max: number
    options: any
    picture: any
}

class feedbackController {

    constructor() {
        makeAutoObservable(this)
    }

    list: FormInterface[] = []
    form: FormInterface = {
        id: 0,
        enabled: true,
        title: '',
        description: '',
        webhook: false,
        elements: [],
        picture: null,
        show_in_list: true,
        type_view: 'default',
        success_title: '',
        success_description: ''
    }

    element: ElementInterface = {
        id: 0,
        enabled: true,
        is_filled: true,
        feedback_id: 0,
        type: '',
        required: false,
        multiple: false,
        label: '',
        placeholder: '',
        is_hidden: false,
        min: 0,
        max: 1,
        options: [],
        picture: null
    }

    selectedType = {
        min: false,
        max: false,
        options: false,
        multiple: false
    }

    types = []

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    setRating(value: number) {
        this.element.max = value
    }

    get(formId: string | number = 0, sorter: any = false) {
        let path = ''
        if(Number(formId) > 0) path = `/feedback/${formId}`
        else {
            path = `/feedback?limit=${this.pagination.limit}&page=${this.pagination.page}&enabled=false`
            if(sorter !== false){
                let direction = sorter.order
                if(direction !== undefined){
                    direction = (direction === 'ascend') ? 'asc' : 'desc'
                    path += `&sorting_type=${sorter.columnKey}&sorting_action=${direction}`
                }
            }
        }

        openApi('get', path)
            .then(result => {
                console.log(result)
                if(Number(formId) > 0) this.form = result.data
                else {
                    this.pagination.total = result.total
                    this.pagination.page = result.page
                    this.pagination.limit = result.limit
                    this.list = result.data
                }
            })
          .catch(err => console.log(err.response))
    }

    save(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('enabled', `${+ this.form.enabled}`)
        data.append('title', this.form.title)
        if(this.form.description !== null) data.append('description', this.form.description)
        data.append('webhook', `${+ this.form.webhook}`)
        data.append('picture', this.form.picture)
        data.append('show_in_list', `${+ this.form.show_in_list}`)
        data.append('type_view', this.form.type_view)
        data.append('success_title', this.form.success_title)
        data.append('success_description', this.form.success_description)
        let path = '/feedback'
        if(this.form.id > 0) path += `/${this.form.id}`

        openApi('post', path, data)
            .then(result => {
                if(this.form.id === 0) window.location.href = `/shop/feedback`
                else {
                    this.get(this.form.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
    }

    getElement(elementID: string | number = 0, formID: string | number) {
        if(elementID > 0){
            openApi('get', `/feedback/element/${elementID}`, {})
                .then(result => {
                    console.log(result)
                    this.element = result.data
                    this.selectedType = this.types.filter((el: any) => el.type === result.data.type)[0]
                })
        }
        this.element.feedback_id = Number(formID)
    }

    saveElement(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()

        data.append('enabled', `${+ this.element.enabled}`)
        data.append('is_filled', `${+ this.element.is_filled}`)
        data.append('feedback_id', `${this.element.feedback_id}`)
        data.append('type', this.element.type)
        data.append('required', `${+ this.element.required}`)
        data.append('multiple', `${+ this.element.multiple}`)
        data.append('label', this.element.label)
        if(this.element.placeholder !== null) data.append('placeholder', this.element.placeholder)
        data.append('is_hidden', `${+ this.element.is_hidden}`)

        if(this.element.type === 'rating') {
            data.append('min', `1`)
            data.append('max', this.element.max > 0 ? `${this.element.max}` : `5`)
        } else {
            data.append('min', `${this.element.min}`)
            data.append('max', `${this.element.max}`)
        }

        if(this.element.options.length > 0){
            this.element.options.map((option: any, s: number) => {
                let optionForPost = {
                    value: option.value,
                    default: option.default,
                }
                if(option.id > 0) { // @ts-ignore
                    optionForPost['id'] = option.id
                }

                Object.keys(optionForPost).map((key: any) => {
                    data.append(`options[${s}][${key}]`, option[key])
                })
            })
        }

        let path = '/feedback/element'
        if(this.element.id > 0) path += `/${this.element.id}`

        openApi('post', path, data)
            .then(result => {
                if(this.element.id === 0) window.location.href = `/shop/feedback/${this.element.feedback_id}`
                else {
                    this.getElement(this.element.id, this.element.feedback_id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
    }

    getTypes() {
        openApi('get', '/feedback/types')
            .then(result => {
                this.types = result.data
            })
          .catch(err => console.log(err.response))
    }

    remove(formID: string) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/feedback/${formID}`)
                .then(result => {
                    this.get()
                })
        }
    }

    removeElement(elementID: string) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/feedback/element/${elementID}`)
                .then(result => {
                    window.location.reload()
                })
        }
    }

    set(name: string, value: any) {
        //@ts-ignore
        this.form[name] = value
    }

    setElement(name: string, value: any) {
        //@ts-ignore
        this.element[name] = value
        if(name === 'type'){
            this.element.options = []
            // @ts-ignore
            this.element.is_filled = this.types.filter(el => el.type === value)?.[0]?.is_filled ?? false
        }
    }

    moveElement(feedbackID: number | string, elementID: number | string, action: string) {
        openApi('put', `/feedback/sorting/elements/${feedbackID}/${elementID}/${action}`)
            .then(result => {
                this.form.elements = result.data
            })
    }

    removeOption(index: number) {
        let item = this.element.options[index]
        if(item.id > 0){
            if(window.confirm(i.t('def:confirms.delete'))) {
                openApi('delete', `/feedback/option/${item.id}`)
                    .then(result => {})
            }
        }
        this.element.options.splice(index, 1)
    }
    
    moveOption(index: number, action: string) {
        let item = this.element.options[index]
        if(item.id > 0){
            openApi('put', `/feedback/sorting/options/${this.element.id}/${item.id}/${action}`)
                .then(result => {
                    console.log(result)
                })
        }

        if(action === 'up'){
            if(index === 0) return
            [this.element.options[index], this.element.options[index - 1]] = [this.element.options[index - 1], this.element.options[index]]
        } else {
            if(index === this.element.options.length - 1) return
            [this.element.options[index], this.element.options[index + 1]] = [this.element.options[index + 1], this.element.options[index]]
        }
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

    upload(image: any, type: string = 'form') {
        // @ts-ignore
        this[type].picture = image
        return false
    }

    removeImage(id: string | number, formID: string | number = 0, type: string = 'form') {
        if(window.confirm(i.t('def:confirms.delete'))) {
            let path
            if(type === 'form') path = `/feedback/picture/${id}`
            else path = `/feedback/element/picture/${id}`
            openApi('delete', path)
                .then(result => {
                    if(type === 'form') this.get(id)
                    else this.getElement(id, formID)
                })
        }
    }

    readAll() {
        openApi('post', `/feedback/read/feedback`)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }

    multipleRead() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/feedback/read/feedback`, data)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }

    multipleDeleteFeedbacks() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/feedback/delete/multiple`, data)
            .then(result => {
                this.get()
                __multiple.clear()
            })
    }

}

export default new feedbackController()