import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../../translations/i'

interface ProjectInterface {
    cities_enabled: boolean
    cities_view: number
    warehouses_enabled: boolean
    warehouses_view: number
    warehouses_show_other_warehouses: boolean
    warehouses_show_all_products: boolean
    warehouse_selection_type: string
    view_categories_level_1: number
    view_categories_level_2: number
    view_categories_level_3: number
    view_products: number
    view_products_in_home: number
    tab_bar_enabled: boolean
    import_action_elements: number
    default_dialing_code: string
    telegram_bot_token: string
    web_header: string
    web_footer: string
    web_prefix: string
    web_postfix: string
    web_main_title: string
    web_main_description: string
    web_main_keywords: string
    web_favicon: any
    web_logotype_horizontal: any
    web_footer_app_image: any
    bonus: boolean
    welcome_bonus: boolean
    bonuses_expire: boolean,
    welcome_bonus_size: number,
    combustion_days: number,
    percent_bonus_cart: number,
    cashback_percent: number
    bonus_card: boolean,
    bonus_card_value: null | string
    bonus_card_type: string
    bonus_description: string | null
    delivery_intervals_enabled: boolean
    pickup_intervals_enabled: boolean
    express_delivery_intervals_enabled: boolean
    roadmap: boolean
    auto_order_cancellation: number,
    place_groups_view: number,
    onboarding_enabled: boolean,
    onboarding_view_type: number,
    promo_slide_enabled: boolean,
    promo_slide_view_type: number
    update_navigation_warehouse: boolean
    referral_system: boolean
    rf_points_inviting_count: number
    rf_points_inviting_burn: number
    rf_points_invited_count: number
    rf_points_invited_burn: number
    rf_condition: number
    rf_description: string | null
    referral_promo_code_title: string | null
    referral_promo_code_text: string | null
    referral_promo_code_share: string | null
    view_articles_level_1: number
    share_text: null | string
    deeplink: null | string
    code_receive_order_enabled: boolean
    code_receive_order_validity_enabled: boolean
    code_receive_order_type: string
    code_receive_order_format: string
    firebase_json: string | null
    hide_prices_un_users: boolean
    product_view_types_images: number
    prohibit_registration: boolean
    scan_coupon_enabled: boolean
    scan_coupon_type: string
    scan_coupon_value: string
    share_product: boolean
    bonus_accrue_upon_write_off: boolean
    bonus_charge_old_price: boolean
    auto_select_variant: boolean
    enabled_legal_entities: boolean
    api_fns_token: string | null
    text_for_blocked_user: string | null
}

class projectController {
    constructor() {
        makeAutoObservable(this)
    }

    project: ProjectInterface = {
        cities_enabled: false,
        cities_view: 1,
        warehouses_enabled: false,
        warehouses_view: 1,
        warehouses_show_other_warehouses: false,
        warehouses_show_all_products: false,
        warehouse_selection_type: 'list',
        view_categories_level_1: 1,
        view_categories_level_2: 1,
        view_categories_level_3: 1,
        view_products: 1,
        view_products_in_home: 1,
        tab_bar_enabled: true,
        import_action_elements: 0,
        default_dialing_code: '+',
        telegram_bot_token: 'е',
        web_header: '',
        web_footer: '',
        web_prefix: '',
        web_postfix: '',
        web_main_title: '',
        web_main_description: '',
        web_main_keywords: '',
        web_favicon: null,
        web_logotype_horizontal: null,
        web_footer_app_image: null,
        bonus: false,
        welcome_bonus: false,
        bonuses_expire: false,
        welcome_bonus_size: 0,
        combustion_days: 0,
        percent_bonus_cart: 0,
        cashback_percent: 0,
        bonus_card: false,
        bonus_card_value: null,
        bonus_card_type: 'qrcode',
        bonus_description: null,
        delivery_intervals_enabled: true,
        pickup_intervals_enabled: true,
        express_delivery_intervals_enabled: true,
        roadmap: false,
        auto_order_cancellation: 0,
        place_groups_view: 1,
        onboarding_view_type: 1,
        onboarding_enabled: true,
        promo_slide_enabled: true,
        promo_slide_view_type: 1,
        update_navigation_warehouse: false,
        referral_system: false,
        rf_points_inviting_count: 0,
        rf_points_inviting_burn: 0,
        rf_points_invited_count: 0,
        rf_points_invited_burn: 0,
        rf_condition: 0,
        rf_description: null,
        referral_promo_code_title: null,
        referral_promo_code_text: null,
        referral_promo_code_share: null,
        view_articles_level_1: 1,
        share_text: null,
        deeplink: null,
        code_receive_order_enabled: false,
        code_receive_order_validity_enabled: false,
        code_receive_order_type: 'barcode',
        code_receive_order_format: '',
        firebase_json: null,
        hide_prices_un_users: false,
        product_view_types_images: 0,
        prohibit_registration: false,
        scan_coupon_enabled: false,
        scan_coupon_type: 'qr',
        scan_coupon_value: 'code',
        share_product: true,
        bonus_accrue_upon_write_off: true,
        bonus_charge_old_price: true,
        auto_select_variant: false,
        enabled_legal_entities: false,
        api_fns_token: null,
        text_for_blocked_user: null
    }

    bonusMassAssignment = false

    bonusMassAssignmentData: any = {
        bonuses_add: 0,
        bonuses_add_combustion_date: null
    }

    isset = false

    get() {
        openApi('get', 'settings')
            .then(result => {
                console.log(result)
                this.project = result.data.project
                this.isset = true
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        this.saving()
    }

    saving() {
        let data = new FormData()

        data.append('cities_enabled', `${+ this.project.cities_enabled}`)
        data.append('cities_view', `${this.project.cities_view}`)
        data.append('warehouses_enabled', `${+ this.project.warehouses_enabled}`)
        data.append('warehouses_view', `${this.project.warehouses_view}`)
        data.append('warehouses_show_other_warehouses', `${+ this.project.warehouses_show_other_warehouses}`)
        data.append('warehouses_show_all_products', `${+ this.project.warehouses_show_all_products}`)
        data.append('warehouse_selection_type', this.project.warehouse_selection_type)
        data.append('view_categories_level_1', `${this.project.view_categories_level_1}`)
        data.append('view_categories_level_2', `${this.project.view_categories_level_2}`)
        data.append('view_categories_level_3', `${this.project.view_categories_level_3}`)
        data.append('view_articles_level_1', `${this.project.view_articles_level_1}`)
        data.append('view_products', `${this.project.view_products}`)
        data.append('view_products_in_home', `${this.project.view_products_in_home}`)
        data.append('place_groups_view', `${this.project.place_groups_view}`)
        data.append('tab_bar_enabled', `${+ this.project.tab_bar_enabled}`)
        data.append('import_action_elements', `${+ this.project.import_action_elements}`)
        data.append('default_dialing_code', this.project.default_dialing_code)
        data.append('telegram_bot_token', this.project.telegram_bot_token)
        data.append('web_header', this.project.web_header)
        data.append('web_footer', this.project.web_footer)

        data.append('web_prefix', this.project.web_prefix)
        data.append('web_postfix', this.project.web_postfix)
        data.append('web_main_title', this.project.web_main_title)
        data.append('web_main_description', this.project.web_main_description)
        data.append('web_main_keywords', this.project.web_main_keywords)

        data.append('web_favicon', this.project.web_favicon)
        data.append('web_logotype_horizontal', this.project.web_logotype_horizontal)
        data.append('web_footer_app_image', this.project.web_footer_app_image)

        data.append('bonus', `${+ this.project.bonus}`)
        data.append('welcome_bonus', `${+ this.project.welcome_bonus}`)
        data.append('bonuses_expire', `${+ this.project.bonuses_expire}`)
        data.append('welcome_bonus_size', `${this.project.welcome_bonus_size}`)
        data.append('combustion_days', `${this.project.combustion_days}`)
        data.append('percent_bonus_cart', `${this.project.percent_bonus_cart}`)
        data.append('cashback_percent', `${this.project.cashback_percent}`)
        data.append('bonus_card', `${+ this.project.bonus_card}`)
        data.append('bonus_card_value', this.project.bonus_card_value ?? '')
        data.append('bonus_card_type', `${this.project.bonus_card_type}`)
        data.append('bonus_description', this.project.bonus_description ?? '')

        data.append('delivery_intervals_enabled', `${+ this.project.delivery_intervals_enabled}`)
        data.append('pickup_intervals_enabled', `${+ this.project.pickup_intervals_enabled}`)
        data.append('express_delivery_intervals_enabled', `${+ this.project.express_delivery_intervals_enabled}`)
        data.append('update_navigation_warehouse', `${+ this.project.update_navigation_warehouse}`)

        data.append('roadmap', `${+ this.project.roadmap}`)
        data.append('auto_order_cancellation', `${this.project.auto_order_cancellation}`)

        data.append('onboarding_enabled', `${+ this.project.onboarding_enabled}`)
        data.append('onboarding_view_type', `${this.project.onboarding_view_type}`)
        data.append('promo_slide_enabled', `${+ this.project.promo_slide_enabled}`)
        data.append('promo_slide_view_type', `${this.project.promo_slide_view_type}`)

        data.append('referral_system', `${+ this.project.referral_system}`)
        data.append('rf_points_inviting_count', `${+ this.project.rf_points_inviting_count}`)
        data.append('rf_points_inviting_burn', `${+ this.project.rf_points_inviting_burn}`)
        data.append('rf_points_invited_count', `${+ this.project.rf_points_invited_count}`)
        data.append('rf_points_invited_burn', `${+ this.project.rf_points_invited_burn}`)
        data.append('rf_condition', `${+ this.project.rf_condition}`)
        if(this.project.rf_description) data.append('rf_description', this.project.rf_description)

        data.append('referral_promo_code_title', this.project.referral_promo_code_title ?? '')
        data.append('referral_promo_code_text', this.project.referral_promo_code_text ?? '')
        data.append('referral_promo_code_share', this.project.referral_promo_code_share ?? '')

        if(this.project.share_text) data.append('share_text', this.project.share_text)
        if(this.project.deeplink) data.append('deeplink', this.project.deeplink)

        data.append('code_receive_order_enabled', `${+ this.project.code_receive_order_enabled}`)
        data.append('code_receive_order_validity_enabled', `${+ this.project.code_receive_order_validity_enabled}`)
        data.append('code_receive_order_type', this.project.code_receive_order_type)
        data.append('code_receive_order_format', this.project.code_receive_order_format)

        if(this.project.firebase_json) data.append('firebase_json', this.project.firebase_json)

        data.append('hide_prices_un_users', `${+ this.project.hide_prices_un_users}`)
        data.append('product_view_types_images', `${+ this.project.product_view_types_images}`)

        data.append('prohibit_registration', `${+ this.project.prohibit_registration}`)

        data.append('scan_coupon_enabled', `${+ this.project.scan_coupon_enabled}`)
        data.append('scan_coupon_type', `${this.project.scan_coupon_type}`)
        data.append('scan_coupon_value', `${this.project.scan_coupon_value}`)

        data.append('share_product', `${+ this.project.share_product}`)

        data.append('bonus_accrue_upon_write_off', `${+ this.project.bonus_accrue_upon_write_off}`)
        data.append('bonus_charge_old_price', `${+ this.project.bonus_charge_old_price}`)

        data.append('auto_select_variant', `${+ this.project.auto_select_variant}`)

        data.append('enabled_legal_entities', `${+ this.project.enabled_legal_entities}`)
        if(this.project.api_fns_token) data.append('api_fns_token', this.project.api_fns_token)

        if(this.project.text_for_blocked_user) data.append('text_for_blocked_user', this.project.text_for_blocked_user)

          openApi('post', 'settings', data)
            .then(result => {
                console.log(result)
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit')
                });
            })
    }

    set(name: string, value: any, save: boolean = false) {
        //@ts-ignore
        this.project[name] = value

        if(save) this.saving()
    }

    clearAllCatalog() {
        openApi('post', '/shop/clear')
            .then(result => {
                console.log(result)
                notification.success({
                    message: i.t('def:success.complete'),
                    description: ''
                });
            })
    }

    openBonusMassAssignment() {
        this.bonusMassAssignment = true
        this.bonusMassAssignmentData = {
            bonuses_add: 0,
            bonuses_add_combustion_date: null
        }
    }

    saveBonusMassAssignment() {
        const data = new FormData()
        data.append('count_bonus', this.bonusMassAssignmentData.bonuses_add)
        if(this.bonusMassAssignmentData.bonuses_add_combustion_date) data.append('combustion_date', this.bonusMassAssignmentData.bonuses_add_combustion_date)

        openApi('post', `/bonus_program/mass_assignment`, data)
          .then(result => {
              console.log(result)
              this.closeBonusMassAssignment()

              notification.success({
                  message: i.t('def:success.complete'),
                  description: i.t('def:Bonuses awarded to all users')
              })
          })
    }

    closeBonusMassAssignment() {
        this.bonusMassAssignment = false
        this.bonusMassAssignmentData = {
            bonuses_add: 0,
            bonuses_add_combustion_date: null
        }
    }

    reIndexCatalog() {
        if(window.confirm(i.t('def:reIndexCatalogConfirm'))) {
            openApi('post', '/settings/reindex_catalog')
              .then(res => {

              })
              .catch(err => {

              })
        }

    }

    handleChangeReceiveCode(e: string) {
        const newValue = e

        const regex = /^[NL-]*$/;
        if (regex.test(newValue) || newValue === "") {
            this.project.code_receive_order_format = newValue
        }
    }
}

export default new projectController()