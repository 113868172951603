import { makeAutoObservable, runInAction } from 'mobx'
import { openApi } from '../../../helpers/request'
import i from '../../../translations/i'
import { FormEvent } from 'react'
import { notification } from 'antd'
import __select from '../../../helpers/select_link/__select.controller'
import { __cpl } from '../custom_product_lists/__cpl'

class GroupController {

    pagination = {
        limit: 10,
        page: 1,
        total: 0
    }

    list: any[] = []

    tree: any[] = []

    item: any = {
        id: 0,
        name: '',
        id_crm: '',
        enabled: true,
        conditions: [],
        tags: []
    }

    new_tag: string | null = null

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        let uri = `/product_groups?limit=${this.pagination.limit}&page=${this.pagination.page}`

        openApi('get', uri)
            .then(result => {
                this.list = result.data
                this.pagination.total = result.total
            })
            .catch(error => console.log(error.response))
    }

    getOne(groupID: number | string) {
        openApi('get', `/product_groups/${groupID}?with[]=tags`)
            .then(result => {
                console.log(result.data)
                this.item = result.data
                let products: any[] = []
                let productIDs: any[] = []

                if(result.data?.products) {
                    result.data.products.map((el: { product: any }) => {
                        if(el.product) {
                            products.push(el.product)
                            productIDs.push(el.product.id)
                        }
                    })
                }

                runInAction(() => this.item.products = products)
                runInAction(() => __select.select.value_array = productIDs)

                let listsIDs: any[] = []

                if(result.data?.conditions) {
                    result.data.conditions.map((el: any) => {
                        listsIDs.push(el.id)
                    })
                }

                runInAction(() => __cpl.selectedLists = listsIDs)
            })
            .catch(error => console.log(error.response))
    }

    deleteOne(groupID: number) {
        openApi('delete', `/product_groups/${groupID}`)
            .then(() => {
                this.getList()
            })
            .catch(error => console.log(error.response))

    }

    setPage(page: number) {
        runInAction(() => this.pagination.page = page)
        this.getList()
    }

    sendForm(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()

        if(this.item.id > 0) {
            data.append('id', this.item.id)
        }
        data.append('name', this.item.name)
        data.append('enabled', String(Number(this.item.enabled)))
        if(this.item.id_crm === null) this.item.id_crm = ''
        data.append('id_crm', this.item.id_crm)

        if(__select.select.value_array?.length > 0) {
            __select.select.value_array.map((el: any, key: any) => {
                data.append(`products[${key}]`, el)
            })
        }

        if(__cpl.selectedLists?.length > 0) {
            __cpl.selectedLists.map((el, key) => {
                data.append(`lists[${key}]`, el)
            })
        }

        if(this.item.tags.length > 0) {
            this.item.tags.map((el: string, index: number) => {
                data.append(`tags[${index}]`, el)
            })
        }

        openApi('post', '/product_groups/', data)
            .then(result => {
                if(this.item.id > 0) {
                    this.getOne(this.item.id)
                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.edit')
                    })
                } else {
                    window.location.href = `/catalog/group/${result.data.id}`
                }
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.data.message ?? 'error'
                })
            })
    }

    addTag() {
        this.item.tags.push(this.new_tag)
        this.new_tag = null
    }

    deleteTag(tag: string) {
        this.item.tags = this.item.tags.filter((el: string) => el !== tag)
    }
}

export const __group = new GroupController()