import { observer } from 'mobx-react-lite'
import { Row, Col, Input, Divider, InputNumber, Select } from "antd";
import { SwitchBlock } from '../../../../components/SwitchBlock'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import React, { useEffect } from 'react'
import __select from "../../../../helpers/select_entity/__select.controller";
import { SelectEntity } from "../../../../helpers/select_entity/SelectEntity";

export const ProductMarketing = observer(() => {

    return(
        <>
            <Row gutter={[20, 20]} align="bottom">
                <Col span={24}>
                    <SwitchBlock array={[
                        { label: i.t('product:marketing.ban_on_discount'), click: (e: any) => __product.item.ban_on_discount = e, value: __product.item.ban_on_discount },
                        { label: i.t('product:bonus_ban'), click: (e: any) => __product.item.bonus_ban = e, value: __product.item.bonus_ban },
                    ]} />
                </Col>
                <Divider />
                <Col span={8}>
                    <SwitchBlock array={[
                        { label: i.t('def:increased_cashback'), click: (e: any) => __product.item.increased_cashback = e, value: __product.item.increased_cashback, block: 2 },
                    ]} />
                </Col>
                {__product.item.increased_cashback
                    ? <>
                        <Col span={8}>
                            <label className="formLabel">{i.t('def:type')}</label>
                            <Select
                                style={{ width: '100%' }}
                                options={[
                                    {label: i.t('def:increased_cashback_type.one'), value: 0},
                                    {label: i.t('def:increased_cashback_type.two'), value: 1},
                                ]}
                                value={__product.item.increased_cashback_type}
                                onChange={ (e) => runInAction(() => __product.item.increased_cashback_type = e) }
                            />
                        </Col>
                        <Col span={8}>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:status_order.increased_cashback_value')}</label>
                                <InputNumber
                                    style={{width: `100%` }}
                                    value={__product.item.increased_cashback_value}
                                    onChange={ (e) => runInAction(() => __product.item.increased_cashback_value = e) }
                                    required
                                />
                            </Col>
                        </Col>
                    </>
                    : <></>
                }
                <Divider />
                <Col span={24}>
                    <SwitchBlock array={[
                        { label: i.t('def:viewCustomButton'), click: (e: any) => __product.item.custom_button_enabled = e, value: __product.item.custom_button_enabled },
                    ]} />
                </Col>
                {__product.item.custom_button_enabled
                    ? <>
                        <Col span={24}>
                            <label className="formLabel">{i.t('product:customButtonText')}</label>
                            <Input
                                value={__product.item.custom_button_text}
                                onChange={ (e) => runInAction(() => __product.item.custom_button_text = e.target.value) }
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <SelectEntity
                              type={__select.select.type}
                              element={__select.select.value}
                              item_element={__select.select.element}
                            />
                        </Col>
                    </>
                    : <></>
                }
            </Row>
        </>
    )
})