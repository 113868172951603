import { makeObservable, observable } from 'mobx'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'
import { openApi } from "../../helpers/request";

class NewsTypesController {

  list: any[] = []
  item: any = {
    id: 0,
    name: null,
    color_hex: '000000',
    color_rgb: ''
  }

  pagination = {
    total: 0,
    limit: 10,
    page: 1,
    offset: 0
  }

  constructor() {
    makeObservable(this, {
      list: observable,
      item: observable,
      pagination: observable,
    })
  }

  getList(limit: null | Number = null) {
    limit = limit ? limit : this.pagination.limit
    openApi('get', `/news_types?limit=${limit}&page=${this.pagination.page}`)
      .then(result => {
        console.log(result.data)
        this.list = result.data
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  getOne(id: any) {
    if(id > 0)
      openApi('get', `/news_types/${id}`)
        .then(result => {
          console.log(result.data)
          this.item = result.data
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  delete(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/news_types/${id}`)
        .then(result => {
          this.getList()
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    data.append('name', this.item.name ? `${this.item.name}` : '')
    data.append('color_hex', this.item.color_hex ? `${this.item.color_hex}` : '000000')

    openApi('post', `/news_types/${this.item.id}`, data)
      .then(result => {
        console.log(result.data)
        if(this.item.id && this.item.id > 0)
          this.item = result.data
        else window.location.href = `/news_types`
      })
      .catch(result => {
        console.log(result.response)
      })
  }

}

export default new NewsTypesController()