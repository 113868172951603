import { observer } from 'mobx-react-lite'
import { storeLocal } from '../helpers/stores'
import { Popover } from 'antd'
import React from 'react'
import i from '../translations/i'

export const ErrorByPricingPlan = observer(({ content, plan = 0, black_mark = false }: any) => {

    return storeLocal.get('pricing_plan') < plan || black_mark
        ? <Popover placement="bottom" title={black_mark ? "Функционал доступен за дополнительную плату" : i.t('def:errors.pricing_plan')}>{content}</Popover>
        : content
})