import { makeAutoObservable, runInAction } from "mobx";
import { FormEvent } from "react";
import { openApi } from "../../../helpers/request";
import i from "../../../translations/i";
import { notification } from "antd";
import __select from "../../../helpers/select_entity/__select.controller";
import { __cpl } from "../../catalog/custom_product_lists/__cpl";

class CouponController {

  list: any[] = []
  item: any = {
    id: null,
    id_crm: null,
    enabled: true,
    name: '',
    code: '',
    text: '',
    count: 0,
    countall: 0,
    dateend: null,
    datestart: null,
    onetouser: false,
    firstorder: false,
    birthday: false,
    birthday_limit: 0,
    type: 0,
    value: null,
    summcard: 0,
    nocount: true,
    description: null,
    public: true,
    color_hex: 'ffffff',
    product_id: null,
    users: [],
    products: [],
    product: null,
    prohibit_write_off: false,
    disable_accrual: false
  }

  pagination = {
    limit: 10,
    page: 1,
    total: 0
  }

  segment = 'main'
  segment_type = 'public'

  filter = {
    name: null,
    code: null
  }

  statistics: any = {
    filter: {
      date_from: null,
      date_to: null
    },
    data: [],
    total: 0,
    page: 1
  }

  constructor() {
    makeAutoObservable(this)
  }

  getList() {

    let uri = `/coupons?limit=${this.pagination.limit}&page=${this.pagination.page}&type=${this.segment_type}`

    if(this.filter.name) uri += `&filter[name]=${this.filter.name}`
    if(this.filter.code) uri += `&filter[code]=${this.filter.code}`

    console.log(uri)

    openApi('get', uri)
      .then(result => {
        console.log(result)
        this.list = result.data
        this.pagination.page = result.page
        this.pagination.total = result.total
        this.pagination.limit = result.limit
      })
  }

  getCoupon(coupon_id: any) {
    if(coupon_id && coupon_id > 0) {
      openApi('get', `/coupons/${coupon_id}`)
        .then(result => {
          console.log(result.data)
          this.item = result.data

          if(result.data.product && result.data.product.id)
            __select.select.value = result.data.product.id

          if(result.data.products && Array.isArray(result.data.products)) {
            __cpl.selectedLists = result.data.products.map((el: any) => el.custom_list_id)
          }
        })
    }
  }

  setPage(page: number) {
    this.pagination.page = page
    this.getList()
  }

  deleteCoupon(coupon_id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/coupons/${coupon_id}`)
        .then(result => {
          this.getList()
        })
  }

  save(e: FormEvent) {
    e.preventDefault()
    let uri = this.item.id && this.item.id > 0 ? `/coupons/${this.item.id}` : `/coupons`

    const data = new FormData()
    if(this.item.color_hex) data.append('color_hex', this.item.color_hex.replace('#', ''))

    data.append('enabled', `${+ this.item.enabled}`)
    data.append('name', `${this.item.name}`)
    if(this.item.id_crm) data.append('id_crm', `${this.item.id_crm}`)
    data.append('code', `${this.item.code}`)
    data.append('text', `${this.item.text}`)
    if(this.item.count) data.append('count', `${this.item.count}`)
    if(this.item.countall) data.append('countall', `${this.item.countall}`)
    if(this.item.dateend)  data.append('dateend', `${this.item.dateend}`)
    if(this.item.datestart) data.append('datestart', `${this.item.datestart}`)
    data.append('onetouser', `${+ this.item.onetouser}`)
    data.append('firstorder', `${+ this.item.firstorder}`)
    data.append('type', `${this.item.type}`)
    if(this.item.value) data.append('value', `${this.item.value}`)
    if(this.item.summcard) data.append('summcard', `${this.item.summcard}`)
    data.append('nocount', `${+ this.item.nocount}`)
    if(this.item.description) data.append('description', `${this.item.description}`)
    data.append('public', `${+ this.item.public}`)
    if(__select.select.value) data.append('product_id', `${__select.select.value}`)
    data.append('birthday', `${+ this.item.birthday}`)
    data.append('birthday_limit', `${+ this.item.birthday_limit}`)

    data.append('prohibit_write_off', `${+ this.item.prohibit_write_off}`)
    data.append('disable_accrual', `${+ this.item.disable_accrual}`)

    if(this.item.users.length > 0)
      this.item.users.map((el: any, index: number) => {
        data.append(`users[${index}]`, el.user_id)
      })

    if(__cpl.selectedLists.length > 0)
      __cpl.selectedLists.map((el: any, index: number) => {
        data.append(`products[${index}]`, el)
      })

    openApi('post', uri, data)
      .then(result => {
        if(this.item.id && this.item.id > 0) {
          this.getCoupon(this.item.id)
          notification.success({
            message: i.t('def:success.complete'),
            description: i.t('def:success.edit')
          });
        } else {
          window.location.href = `/shop/coupons`
        }
      })
      .catch(error => {
        notification.error({
          message: i.t('def:errors.error'),
          description: error.response.data.data.message ?? i.t('def:errors.error')
        });
      })
  }

  selectUser(user: any) {
    if(this.item.users.find((el: any) => el.user_id === user.id)) {
      this.item.users = this.item.users.filter((el: any) => el.user_id !== user.id)
    } else {
      runInAction(() => {
        this.item.users.push({
          user_id: user.id
        })
      })
    }

    console.log(this.item.users)
    console.log(typeof this.item.users)
  }

  onChangeType(type: string) {
    this.segment_type = type
    this.pagination.page = 1
    this.getList()
  }

  onChangeValue(type: string, value: any) {
    // @ts-ignore
    this.filter[type] = value
    this.pagination.page = 1
    this.getList()
  }

  getStatistics(id: any) {
    let path = `/coupons/history/${id}?page=${this.statistics.page}`

    if(this.statistics.filter.date_from)
      path += `&date_from=${this.statistics.filter.date_from}`

    if(this.statistics.filter.date_to)
      path += `&date_to=${this.statistics.filter.date_to}`

    openApi('get', path)
      .then(result => {
        console.log(result)
        this.statistics.data = result.data
        this.statistics.total = result.total
      })
      .catch(error => {

      })
  }

  onChangePageStatistics(page: number, id: any) {
    this.statistics.page = page
    this.getStatistics(id)
  }

}

export default new CouponController()