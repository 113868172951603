import { makeAutoObservable, runInAction } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../../translations/i'
import projectController from '../project/project.controller'

interface ScheduleInterface {
    schedule_enabled: boolean
    schedule_type: string
    schedule_monday: boolean
    schedule_tuesday: boolean
    schedule_wednesday: boolean
    schedule_thursday: boolean
    schedule_friday: boolean
    schedule_saturday: boolean
    schedule_sunday: boolean
    schedule_time: number[]
    type_file_uri: string
    application: any
}

class __schedule {
    
    schedule: ScheduleInterface = {
        schedule_enabled: false,
        schedule_type: '',
        schedule_monday: false,
        schedule_tuesday: false,
        schedule_wednesday: false,
        schedule_thursday: false,
        schedule_friday: false,
        schedule_saturday: false,
        schedule_sunday: false,
        schedule_time: [],
        type_file_uri: '',
        application: null
    }

    scheduleDisabledTime = []

    file: any = null

    file_type: string = 'xml'

    rkeeperAccount: any = {}
    rkeeperFields: any[] = []
    
    constructor() {
        makeAutoObservable(this)
    }

    get() {
        openApi('get', '/import/settings')
            .then(result => {
                console.log(result)
                this.schedule = result.data

                if(result.schedule_type === 'rkeeper') {
                    this.getRkeeper()
                }
            })
    }

    setDay(key: string, value: boolean) {
        console.log(key, value)
        // @ts-ignore
        this.schedule[key] = !value
    }

    import_now() {
        const data = new FormData()
        data.append('link', this.schedule.type_file_uri)

        openApi('post', '/import/now', data)
            .then(result => {
                console.log(result)
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:import_run_success')
                });
            })
    }

    setScheduleTime(value: any) {
        console.log(this.schedule.schedule_time)
        if(this.scheduleDisabledTime.findIndex(el => el === value.number) > -1) {

        } else {
            if(this.schedule.schedule_time.findIndex(el => `${el}` === `${value.seconds}`) > -1) {
                console.log('this')
                this.schedule.schedule_time = this.schedule.schedule_time.filter(el => `${el}` !== `${value.seconds}`)
            } else {
                this.schedule.schedule_time.push(value.seconds)

            }
            this.reloadDisabledSchedule()
        }
    }

    reloadDisabledSchedule() {
        this.scheduleDisabledTime = []
        this.schedule.schedule_time.map(el => {
            const number = el > 0 ? el / 60 / 60 : 0
            // this.setDisabledSchedule([
            //     this.getIndexOfInterval(number, '-', 1),
            //     this.getIndexOfInterval(number, '-', 2),
            //     this.getIndexOfInterval(number, '+', 1),
            //     this.getIndexOfInterval(number, '+', 2),
            // ])
        })
    }

    saveSchedule() {
        const data = new FormData()
        data.append('schedule_enabled', `${+ this.schedule.schedule_enabled}`)
        data.append('schedule_type', this.schedule.schedule_type)
        data.append('schedule_monday', `${+ this.schedule.schedule_monday}`)
        data.append('schedule_tuesday', `${+ this.schedule.schedule_tuesday}`)
        data.append('schedule_wednesday', `${+ this.schedule.schedule_wednesday}`)
        data.append('schedule_thursday', `${+ this.schedule.schedule_thursday}`)
        data.append('schedule_friday', `${+ this.schedule.schedule_friday}`)
        data.append('schedule_saturday', `${+ this.schedule.schedule_saturday}`)
        data.append('schedule_sunday', `${+ this.schedule.schedule_sunday}`)
        data.append('schedule_time', JSON.stringify(this.schedule.schedule_time))
        data.append('type_file_uri', this.schedule.type_file_uri)

        this.scheduleDisabledTime = []

        openApi('post', '/import/settings', data)
            .then(result => {
                window.location.reload()
            })
          .catch(err => console.log(err.response))
    }

    save(e: FormEvent) {
        e.preventDefault()
        this.saveSchedule()

    }

    upload() {
        const data = new FormData()
        data.append('upload', this.file)

        openApi('post', '/import/file', data)
            .then(result => {
                console.log(result)
                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:importStarted')
                });
                this.file = null
            })
    }

    getRkeeper() {
        openApi('get', '/services/rkeeper/fields')
            .then(fields => {
                runInAction(() => this.rkeeperFields = fields)

                openApi('get', '/services/rkeeper')
                    .then(result => {
                        console.log(result)
                        runInAction(() => {
                            if(result.id) this.rkeeperAccount = result
                        })
                    })
            })
    }

    setType(name: string) {
        this.schedule.schedule_type = String(name)

        if(name === 'rkeeper') {
            this.getRkeeper()
        }
    }

    connectionToRkeeper() {
        const data = new FormData()

        this.rkeeperFields.map((field) => {
            if(this.rkeeperAccount[field.key]) {
                console.log(field.key, this.rkeeperAccount[field.key])
                data.append(field.key, this.rkeeperAccount[field.key])
            }
        })

        openApi('post', '/services/rkeeper', data)
            .then(result => {
                console.log(result)
                this.saveSchedule()
            })
    }
    
}

export default new __schedule()