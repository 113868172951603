import { makeAutoObservable } from 'mobx'
import { openApi } from "../../../helpers/request";
import { FormEvent } from "react";
import i from "../../../translations/i";
import PaymentTypeController from "./payment.type.controller";
import { notification } from "antd";

class PaymentGatewayController {

  list: any[] = []
  item: any = {
    id: null,
    gateway_id: null,
    tax_system_code: 1,
    vat_code: 1,
    save_card: false,
    card_online: false,
    sberpay: false,
    sbp: false,
    mirpay: false,
    tinkoff_pay: false
  }

  constructor() {
    makeAutoObservable(this)
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    data.append('label', this.item.label)
    data.append('shop_id', this.item.shop_id)
    data.append('secret_key', this.item.secret_key)
    data.append('gateway_id', this.item.gateway_id)
    data.append('tax_system_code', this.item.tax_system_code)
    data.append('vat_code', this.item.vat_code)

    let array = ['save_card', 'card_online', 'sberpay', 'sbp', 'mirpay', 'tinkoff_pay']

    array.map((el: string) => data.append(el, `${+ this.item[el]}`))

    let uri = `/payment_gateway/`

    if(this.item.id && this.item.id > 0)
      uri += this.item.id

    openApi('post', uri, data)
      .then((result: any) => {
        console.log(result)
        if(this.item.id && this.item.id > 0)
          window.location.reload()
        else window.location.href = `/settings/payment_gateway`
      })
      .catch(error => {
        console.log(error.response);
        notification.error({
          message: i.t('def:errors.error'),
          description: error.response.data.data.message
        })
      })
  }

  getList() {
    openApi('get', `/payment_gateway`)
      .then((result: any) => {
        console.log(result)
        this.list = result.data
      })
      .catch(error => console.log(error.response))
  }

  getOne(id: any) {
    if(id && id > 0) {
      openApi('get', `/payment_gateway/${id}`)
        .then((result: any) => {
          console.log(result)
          this.item = result.data

          if(!PaymentTypeController.item.id) {
            let array = ['tinkoff_pay', 'mirpay', 'sbp', 'sberpay', 'card_online']

            array.map((el: string) => {
              console.log(this.item[el])
              if(this.item[el]) {
                console.log(el)
                PaymentTypeController.item.type = el
              }
            })
          }

        })
        .catch(error => console.log(error.response))
    }
  }

  destroy(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/payment_gateway/${id}`)
        .then((result: any) => {
          window.location.reload()
        })
        .catch(error => console.log(error.response))
  }

}

export default new PaymentGatewayController()