import { observer } from 'mobx-react-lite'
import { TitlesInterface } from './layout.interfaces'
import { Breadcrumb, Col, Row, Space, Button, Dropdown, Tooltip } from 'antd'
import i from '../translations/i'
import { Helmet } from 'react-helmet-async'
import { ErrorByPricingPlan } from '../components/ErrorByPricingPlan'
import { storeLocal } from '../helpers/stores'
import type { MenuProps } from 'antd'

const ButtonsDropdown = ({ buttons }: any) => {

    const items: MenuProps['items'] = buttons.map((el: any, index: number) => {
        let this_plan = storeLocal.get('pricing_plan')
        let plan = el.plan ? el.plan : 0
        let disabled = this_plan < plan

        if (disabled) return ({
            label: (
                <Tooltip title={i.t('def:errors.pricing_plan')} placement="left">
                    {el.title}
                </Tooltip>
            ),
            key: `dropdown_${index}`,
            danger: el.danger ?? false,
            disabled: disabled
        })

        return el.click ? ({
                label: el.title,
                key: `dropdown_${index}`,
                onClick: () => el.click(),
                danger: el.danger ?? false,
                disabled: disabled
            })
            : ({
                label: (<a href={el.href} target={el.target ? '_blank' : ''}>{el.title}</a>),
                key: `dropdown_${index}`,
                danger: el.danger ?? false,
                disabled: disabled,
                render: (el: any) => <a href={el.href}>{el.title}</a>
            })
    })

    return (
        <>
            <Dropdown.Button
                menu={{ items }}
                trigger={['click']}
            >
                {i.t('def:actions.action')}
            </Dropdown.Button>
        </>
    )
}

const ButtonsList = ({ buttons }: any) => {

    return (
        <Space>
            {buttons.map((el: any, index: number) => {
                let this_plan = storeLocal.get('pricing_plan')
                let plan = el.plan ? el.plan : 0
                let disabled = this_plan < plan || el.black_mark

                return el.click
                    ? (
                        <ErrorByPricingPlan
                            plan={plan}
                            key={`titles_button_render_${index}`}
                            black_mark={el.black_mark ?? false}
                            content={
                                <Button
                                    key={`titles_button_${index}`}
                                    type={disabled ? 'dashed' : (el.type ? el.type : 'default')}
                                    htmlType={el.htmlType ? el.htmlType : 'button'}
                                    onClick={() => el.click()}
                                    danger={!!el.danger}
                                    disabled={disabled}
                                >
                                    {el.title}
                                </Button>
                            }
                        />
                    )
                    : (
                        <ErrorByPricingPlan
                            plan={plan}
                            key={`titles_button_render_${index}`}
                            content={
                                <Button
                                    key={`titles_button_${index}`}
                                    type={disabled ? 'dashed' : (el.type ? el.type : 'default')}
                                    htmlType={el.htmlType ? el.htmlType : 'button'}
                                    href={el.href && el.href}
                                    target={el.target ? '_blank' : ''}
                                    danger={!!el.danger}
                                    disabled={disabled}
                                >
                                    {el.title}
                                </Button>
                            }
                        />
                    )
            })}
        </Space>
    )
}

export const Titles = observer(({ title, buttons = [], breadcrumb = [] }: TitlesInterface) => {

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Row justify="space-between" align="middle" className="title_page" gutter={[10, 10]}>
                {breadcrumb.length > 0 &&
                    <Col xs={{ span: 24 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item href={'/'}>{i.t('crumb:home')}</Breadcrumb.Item>
                            {breadcrumb.length > 0 && breadcrumb.map((el: any, index: number) => (
                                <Breadcrumb.Item key={`titles_crumb_${index}`}
                                                 href={el.href}>{el.title}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                }
                <Col>
                    <h1>{title}</h1>
                </Col>
                <Col className="right">
                    <Space>
                        {buttons && buttons.length > 0 &&
                            <>
                                {buttons.length < 4 ? <ButtonsList buttons={buttons} /> :
                                    <ButtonsDropdown buttons={buttons} />}
                            </>
                        }
                    </Space>
                </Col>
            </Row>
        </>
    )
})