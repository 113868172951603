import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import __order from './__order'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { OrderProducts } from './modules/OrderProducts'
import {
    Row,
    Col,
    Modal,
    Image,
    Tag,
    Table,
    Button,
    Space,
    Select,
    Divider,
    Input,
    InputNumber,
    DatePicker,
    Alert
} from 'antd'
import { storeSession } from '../../../helpers/stores'
import { MapLocation } from '../../../components/map/MapLocation'
import { runInAction } from 'mobx'
import { paymentTypesController } from '../payment/types/controller'
import { getPrice } from '../../../helpers/price'
import dayjs from 'dayjs'
import __delivery from '../../settings/delivery/base/__delivery'
import { __product } from "../../catalog/product/__product";
import type { InputNumberProps } from 'antd'

const payments = new paymentTypesController()

function secondsToHHii(seconds: any) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    return `${padZero(hours)}:${padZero(minutes)}`;
}

function padZero(num: any) {
    return (num < 10 ? '0' : '') + num;
}

export const Order = observer(() => {

    let { id }: any = useParams()

    useEffect(() => {
        if (id > 0) __order.getOrder(id)
        else __order.setOrder()

        __order.getStatuses()
        payments.getList()
        __delivery.get()
    }, [])

    const onChangeModalSum: InputNumberProps['onChange'] = (value) => {
        __order.holding_modal.sum = value ?? 0
        console.log('changed', __order.holding_modal.sum)
    }

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: 'id_crm',
            key: 'id_crm',
            dataIndex: 'id_crm',
        },
        {
            title: i.t('order:picture'),
            key: 'picture',
            render: (text: any, record: any) => record.image ? <Image width={'100px'} src={record.image.path} /> : <></>
        },
        {
            title: i.t('order:name'),
            key: 'name',
            dataIndex: 'name',
            render: (name: string, record: any) => <>
                <p>{name}</p>
                {Array.isArray(record.props) && record.props.map((el: any, i: number) =>
                    record.is_variant ? (<Tag>{el.category.name}: {el.value}</Tag>) : (
                        <Col span={24}>
                            <label>{el.category.name}</label>
                            <Select
                                style={{ 'width': `250px` }}
                                options={el.options}
                                //value={el.values ?? null}
                                onChange={(it: any) => {
                                    let element = record.props[i].options.find((f: any) => f.value === it)
                                    record.props[i].value = element.label
                                    record.props[i].label = el.category.name
                                    record.props[i].id = it
                                }}
                            />
                        </Col>
                    ))}
            </>
        },
        {
            title: i.t('order:unit'),
            dataIndex: 'unit',
            key: 'unit',
            render: (text: any, record: any) => record.unit_id && storeSession.get('project_units').findIndex((el: any) => el.id === record.unit_id) > -1
                ? <>{storeSession.get('project_units').find((el: any) => el.id === record.unit_id).name}</>
                : <></>
        },
        {
            title: i.t('order:vendor'),
            key: 'vendor_code',
            render: (item: any, record: any) => record.vendor_code
        },
        {
            title: i.t('order:price'),
            dataIndex: 'price',
            key: 'price',
            render: (text: any, record: any) => text
        },
        {
            key: 'actions',
            render: (record: any) => (
                <Space>
                    {record.view_variations
                        ? <></>
                        : <>
                            {__order.item[__order.select.type].find((el: any) => el.product_id === record.id)
                                ? <></>
                                : <Button onClick={() => __order.addProduct(record)}>{i.t('def:actions.add')}</Button>
                            }
                        </>
                    }
                </Space>
            )
        }
    ]

    const usersColumns = [
        {
            title: i.t('users:name'),
            key: 'name',
            render: (record: any) => `${record.firstname ?? ''} ${record.lastname ?? ''} ${record.surname ?? ''}`
        },
        {
            title: i.t('users:email'),
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: i.t('users:phone'),
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Button onClick={() => __order.addUser(record)}>{i.t('def:select')}</Button>
                    </Space>
                </>
            ),
        },
    ]

    const buttons: any[] = [
        {
            title: __order.editable ? i.t('def:actions.save') : i.t('def:actions.edit'),
            click: __order.editable ? () => __order.save() : () => __order.setEditable(),
            type: __order.editable ? 'primary' : 'dashed',
            plan: 2
        },
    ]

    if(__order.editable) {
        buttons.unshift({
            title: __order.item.id > 0 ? i.t('def:Cancel Editing') : i.t('def:actions.back'),
            click: () => __order.item.id > 0 ? __order.setEditable() : window.location.href = '/shop/orders',
            type: 'dashed'
        })
    }

    buttons.push({
        title: __order.item.is_archive ? i.t('def:fromArchive') : i.t('def:toArchive'),
        click: () => __order.item.is_archive ? __order.fromArchive(__order.item.id) : __order.toArchive(__order.item.id),
        type: 'dashed'
    })

    if(__order.item.payment_status === 3 && !__order.item.holding) {
        buttons.push({
            title: i.t('def:holding'),
            click: () => __order.holding(__order.item.id),
            type: 'dashed'
        })

        buttons.push({
            title: i.t('def:un_holding'),
            click: () => __order.un_holding(__order.item.id),
            type: 'dashed'
        })
    }

    let breadcrumbs: any[] = [
        { title: i.t('order:orders'), href: '/shop/orders' }
    ]

    if (id > 0) breadcrumbs.push({ title: i.t('def:Order', { id: id }) })
    else breadcrumbs.push({ title: i.t('def:New Order') })

    let pageTitle = id > 0 ? i.t('def:Order', { id: id }) : i.t('def:New Order')

    return (
        <>
            <Titles
                title={pageTitle}
                buttons={buttons}
                breadcrumb={breadcrumbs}
            />



            <Row gutter={[20, 20]}>
                {__order.item.rejection_reason_object
                  ? <Col span={24}>
                      <Alert
                        message={`${i.t('def:rejection_reason')}: ${__order.item.rejection_reason_object.label ?? ''}`}
                        description={__order.item.rejection_reason_comment ? __order.item.rejection_reason_comment : ''}
                        type="error"
                        showIcon
                      />
                  </Col>
                  : <></>}

                {__order.item.code_receive ?<Col span={24}>
                    <div className="order__block order__block-item">
                        <p className="item__text">{i.t('def:Code for issuing an order')}:</p>
                        <p className="item__heading">{__order.item.code_receive}</p>
                    </div>
                </Col> : <></>}

                <Col span={12}>
                    <div className="order__block">
                        {__order.item?.created_at &&
                            <div className="order__block-item">
                                <div className="item__heading">{i.t('order:datetime')}</div>
                                <div
                                    className="item__text">{dayjs(__order.item.created_at).format('DD.MM.YYYY HH:mm')}</div>
                            </div>
                        }

                        <div className="order__block-item">
                            <div className="item__heading">{i.t('order:statusOrder')}</div>
                            <div className="item__text">
                                <Select
                                    options={__order.statuses.map((el: any) => ({
                                        label: el.name,
                                        value: el.id
                                    }))}
                                    value={__order.item.status}
                                    onChange={(e) => runInAction(() => {
                                        __order.item.status = e

                                        if(!__order.editable) {
                                            __order.save(false)
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className="order__block-item">
                            <div className="item__heading">{i.t('order:statusPay')}</div>
                            <div className="item__text">
                                <Select
                                    options={payments.list.map((el: any) => ({
                                        label: el.name,
                                        value: el.id
                                    }))}
                                    value={__order.item.payment_status}
                                    onChange={(e) => runInAction(() => {
                                        __order.item.payment_status = e

                                        if(!__order.editable) {
                                            __order.save(false)
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className="order__block-item">
                            <div className="item__heading">{i.t('order:type')}</div>
                            <div className="item__text">
                                {__order.editable ? (
                                    <>
                                        {__order.item.payment_type_id
                                          ? <Select
                                            options={__delivery.delivery.list_pay_new.map((el: any) => ({
                                                label: el.label,
                                                value: el.id
                                            }))}
                                            value={__order.item.payment_type_id}
                                            onChange={(e) => runInAction(() => __order.item.payment_type_id = e)}
                                          />
                                          : <Select
                                            options={__delivery.delivery.list_pay.map((el: any) => ({
                                                label: el.name,
                                                value: el.id
                                            }))}
                                            value={__order.item.payment_type}
                                            onChange={(e) => runInAction(() => __order.item.payment_type = e)}
                                          />
                                        }
                                    </>
                                  )
                                    :
                                    <>{__order.item.payment_type_object?.name}</>
                                }
                            </div>
                        </div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="order__block">
                        <div className="order__block-item">
                            <div className="item__heading">{i.t('order:price')}</div>
                            <Row gutter={[20, 20]} align="middle">
                                <Col span={12}>
                                    <div className="item__text">
                                        {i.t('order:products')} ({__order.item?.products_sum?.count ?? 0})
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div
                                        className="item__text">{getPrice((__order.item?.products_sum?.sum ?? 0).toFixed(2))}</div>
                                </Col>
                            </Row>
                        </div>

                        {__order.item.delivery_price > 0 &&
                            <div className="order__block-item">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={12}>
                                        <div className="item__text">{i.t('order:deliveries')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div
                                            className="item__text">{getPrice((__order.item.delivery_price).toFixed(2))}</div>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {__order.item.discounts ?
                          <>
                              {__order.item.discounts.map((disc: any) => disc.discount ? (
                                <div className="order__block-item">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={12}>
                                            <div className="item__text">{i.t(`def:purposes.${disc.discount.purpose}`)}</div>
                                        </Col>
                                        <Col span={12}>
                                            <div
                                              className="item__text">{disc.measurement === 1 ? getPrice((disc.amount).toFixed(2)) : `${disc.amount}%`}</div>
                                        </Col>
                                    </Row>
                                </div>
                              ) : <></>)}
                          </> : <></>
                        }

                        {__order.item.invited_id
                            ? <div className="order__block-item">
                              <Row gutter={[20, 20]} align="middle">
                                  <Col span={12}>
                                      <div className="item__text">{i.t('def:referral_user')}</div>
                                  </Col>
                                  <Col span={12}>
                                      <div className="item__text">
                                          <a href={`/users/${__order.item.invited_id}`} target="_blank">{i.t('def:jump.jump')}</a>
                                      </div>
                                  </Col>
                              </Row>
                          </div>
                            : <></>
                        }

                        {__order.item.promo_code_user && __order.item.promo_code_user.map((el: any) => <>
                            <>
                                <div className="order__block-item">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={12}>
                                            <div className="item__text">{i.t('order:promo')}</div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="item__text">{el.promo_code ? el.promo_code.code : "Удален"}</div>
                                        </Col>
                                    </Row>
                                </div>

                                {el.promo_code && el.promo_code.type !== 2 &&
                                  <div className="order__block-item">
                                      <Row gutter={[20, 20]} align="middle">
                                          <Col span={12}>
                                              <div className="item__text">{i.t('order:sale')}</div>
                                          </Col>
                                          <Col span={12}>
                                              <div
                                                className="item__text">{el.promo_code.value} {el.promo_code.type === 0 ? '' : '%'}</div>
                                          </Col>
                                      </Row>
                                  </div>
                                }
                            </>
                        </>)}

                        {(__order.item.cashback > 0 || __order.editable) &&
                            <div className="order__block-item">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={12}>
                                        <div className="item__text">{i.t('order:cashback_add')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="item__text">
                                            {__order.editable ? <>
                                                <InputNumber
                                                  value={__order.item?.cashback ?? 0}
                                                  onChange={e => runInAction(() => __order.item.cashback = e)}
                                                />
                                            </> : <>{__order.item.cashback}</>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {(__order.item.size_bonus_points > 0 || __order.editable) &&
                            <div className="order__block-item">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={12}>
                                        <div className="item__text">{i.t('order:size_bonus_points')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="item__text">
                                            {__order.editable ? <>
                                                <InputNumber
                                                  value={__order.item?.__size_bonus_points ?? 0}
                                                  onChange={e => runInAction(() => __order.item.__size_bonus_points = e)}
                                                  min={__order.item?.size_bonus_points ?? 0}
                                                />
                                            </> : <>
                                                {__order.item.size_bonus_points}
                                            </>}
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        {__order.editable && <Alert message={i.t('def:user_balance', { balance: __order.item.user_balance ?? 0 })} type="warning" />}
                                    </Col>
                                    <Col span={24}>
                                        {__order.editable && <Alert message={i.t('def:user_balance_after_edit', { balance: __order.getUserBalance() })} type="error" />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }

                        {__order.item.change_from > 0 &&
                          <div className="order__block-item">
                              <Row gutter={[20, 20]} align="middle">
                                  <Col span={12}>
                                      <div className="item__text">{i.t('def:Change from')}</div>
                                  </Col>
                                  <Col span={12}>
                                      <div className="item__text">{__order.item.change_from}</div>
                                  </Col>
                              </Row>
                          </div>
                        }

                        <Divider dashed />

                        <div className="order__block-item">
                            <Row gutter={[20, 20]} align="middle">
                                <Col span={12}>
                                    <div className="item__text item__text-bold">{i.t('order:total')}:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="item__text">{getPrice((__order.item.sum ?? 0).toFixed(2))}</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <div className="order__block">
                        <div className="order__block-item">
                            <div className="item__heading">{i.t('order:deliveries')}</div>
                        </div>

                        {__order.item.warehouse_id && __order.item.warehouse ? <div className="order__block-item">
                            <div className="item__text">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={5}>
                                        <div className="item__text item__text-bold">{i.t('def:warehouse')}</div>
                                    </Col>
                                    <Col span={19}>
                                        <div className="item__text">
                                            <a href={`/settings/warehouses/${__order.item.warehouse.id}`} target="_blank">{__order.item.warehouse.name}</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div> : <></>}

                        <div className="order__block-item">
                            <div className="item__text">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={5}>
                                        <div className="item__text item__text-bold">{i.t('order:deliveryType')}</div>
                                    </Col>
                                    <Col span={19}>
                                        <div className="item__text">
                                            {__order.item.delivery_type === 1 ? <>{i.t('order:deliveries')}</> : <>{i.t('order:pickup')}</>}

                                            {__order.item.delivery !== 'default' &&
                                                <>({__order.getDeliveryNameByType(__order.item.delivery)})</>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="order__block-item">
                            <div className="item__text">
                                <Row gutter={[20, 20]} align="middle">
                                    <Col span={5}>
                                        <div className="item__text item__text-bold">{i.t('order:dateDelivery')}</div>
                                    </Col>
                                    <Col span={19}>
                                        <div className="item__text">
                                            {__order.editable ? <>
                                                <DatePicker
                                                    format="DD.MM.YYYY"
                                                    value={__order.item.delivery_date ? dayjs(__order.item.delivery_date) : null}
                                                    placeholder={i.t('order:dateDelivery')}
                                                    onChange={e => {
                                                        if (e) {
                                                            __order.item.delivery_date = e.format()
                                                        }
                                                    }}
                                                    minDate={dayjs()}
                                                />
                                            </> : <>{dayjs(__order.item.delivery_date).format('DD.MM.YYYY')}</>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        {__order.item.delivery_interval_start && __order.item.delivery_interval_end
                        ? <div className="order__block-item">
                              <div className="item__text">
                                  <Row gutter={[20, 20]} align="middle">
                                      <Col span={5}>
                                          <div className="item__text item__text-bold">{i.t('def:Delivery interval')}</div>
                                      </Col>
                                      <Col span={19}>
                                          <div className="item__text">
                                              <p>{secondsToHHii(__order.item.delivery_interval_start)} - {secondsToHHii(__order.item.delivery_interval_end)}</p>
                                          </div>
                                      </Col>
                                  </Row>
                              </div>
                          </div>
                          : <></>}

                        {__order.item.delivery_interval_express_time ?
                          <div className="order__block-item">
                              <div className="item__text">
                                  <Row gutter={[20, 20]} align="middle">
                                      <Col span={5}>
                                          <div className="item__text item__text-bold">{i.t('def:Delivery interval EXPRESS')}</div>
                                      </Col>
                                      <Col span={19}>
                                          <div className="item__text">
                                              <p>{i.t('def:Delivery in minutes', {
                                                  minutes: __order.item.delivery_interval_express_time
                                              })}</p>
                                          </div>
                                      </Col>
                                  </Row>
                              </div>
                          </div>
                          : <></>}

                        {(__order.item?.address || __order.editable) &&
                          <>
                              <div className="order__block-item">
                                  <div className="item__text">
                                      <Row gutter={[20, 20]} align="middle">
                                          <Col span={5}>
                                              <div className="item__text item__text-bold">{i.t('def:address')}</div>
                                          </Col>
                                          <Col span={19}>
                                              <div className="item__text">
                                                  {__order.editable ? <>
                                                      <Input
                                                        value={__order.item.address}
                                                        onChange={e => runInAction(() => __order.item.address = e.target.value)}
                                                      />
                                                  </> : <>{__order.item.address}</>}
                                              </div>
                                          </Col>
                                      </Row>
                                  </div>
                              </div>

                              {__order.item.cottage ?
                                <div className="order__block-item">
                                    <div className="item__text">
                                        <Row gutter={[20, 20]} align="middle">
                                            <Col span={5}>
                                                <div className="item__text item__text-bold">{i.t('def:Private house')}</div>
                                            </Col>
                                            <Col span={19}>
                                                <div className="item__text">
                                                    {__order.item.cottage}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div> : <></>
                              }

                              {__order.item.zip &&
                                  <>
                                      <div className="order__block-item">
                                          <Row gutter={[20, 20]} align="middle">
                                              <Col span={5}>
                                                  <div className="item__text item__text-bold">{i.t('order:Zip')}:</div>
                                              </Col>
                                              <Col span={19}>
                                                  <div className="item__text">{__order.item.zip}</div>
                                              </Col>
                                          </Row>
                                      </div>
                                  </>
                              }

                              {__order.item.apartment &&
                                  <>
                                      <div className="order__block-item">
                                          <Row gutter={[20, 20]} align="middle">
                                              <Col span={5}>
                                                  <div className="item__text item__text-bold">{i.t('def:Apartment')}:</div>
                                              </Col>
                                              <Col span={19}>
                                                  <div className="item__text">{__order.item.apartment}</div>
                                              </Col>
                                          </Row>
                                      </div>
                                  </>
                              }

                              {__order.item.entrance &&
                                  <>
                                      <div className="order__block-item">
                                          <Row gutter={[20, 20]} align="middle">
                                              <Col span={5}>
                                                  <div className="item__text item__text-bold">{i.t('def:Entrance')}:</div>
                                              </Col>
                                              <Col span={19}>
                                                  <div className="item__text">{__order.item.entrance}</div>
                                              </Col>
                                          </Row>
                                      </div>
                                  </>
                              }

                              {__order.item.floor &&
                                  <>
                                      <div className="order__block-item">
                                          <Row gutter={[20, 20]} align="middle">
                                              <Col span={5}>
                                                  <div className="item__text item__text-bold">{i.t('def:Floor')}:</div>
                                              </Col>
                                              <Col span={19}>
                                                  <div className="item__text">{__order.item.floor}</div>
                                              </Col>
                                          </Row>
                                      </div>
                                  </>
                              }

                          </>
                        }

                        {((__order.item?.address_full && __order.item.address_full !== '') || __order.editable) &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={5}>
                                            <div className="item__text item__text-bold">{i.t('order:fulladdress')}</div>
                                        </Col>
                                        <Col span={19}>
                                            <div className="item__text">
                                                {__order.editable ? <>
                                                    <Input
                                                        value={__order.item.address_full}
                                                        onChange={e => runInAction(() => __order.item.address_full = e.target.value)}
                                                    />
                                                </> : <>{__order.item.address_full}</>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }

                        {(__order.item?.latitude && __order.item?.longitude) &&
                            <div className="order__block-item">
                                <MapLocation coordinates={[__order.item.latitude, __order.item.longitude]} />
                            </div>
                        }
                    </div>
                </Col>

                <Col span={24}>
                    <div className="order__block">
                        <div className="order__block-item">
                            <div className="item__heading">
                                {i.t('order:buyer')}
                                {__order.editable &&
                                    <>
                                        {__order.item.user_id > 0 ? <Button
                                            type="dashed"
                                            danger
                                            onClick={() => __order.toggleUser()}
                                        >{i.t('def:actions.remove')}</Button> : <Button
                                            type="dashed"
                                            onClick={() => __order.toggleUser()}
                                        >{i.t('def:select')}</Button>}
                                    </>
                                }
                            </div>
                        </div>

                        {(__order.item.firstname || __order.item.lastname) &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={5}>
                                            <div className="item__text item__text-bold">{i.t('order:fio')}</div>
                                        </Col>
                                        <Col span={19}>
                                            <div
                                                className="item__text">{__order.item.firstname} {__order.item.lastname}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }

                        {__order.item.phone &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={5}>
                                            <div className="item__text item__text-bold">{i.t('order:phone')}</div>
                                        </Col>
                                        <Col span={19}>
                                            <div className="item__text">{__order.item.phone}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }

                        {__order.item.email &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[20, 20]} align="middle">
                                        <Col span={5}>
                                            <div className="item__text item__text-bold">{i.t('order:email')}</div>
                                        </Col>
                                        <Col span={19}>
                                            <div className="item__text">{__order.item.email}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }

                        {(__order.editable && __order.item.user_id) &&
                            <div className="order__block-item">
                                {__order.item.recipient ? <Button
                                    type="dashed"
                                    danger
                                    onClick={() => __order.toggleRecipient()}
                                >{i.t('def:Remove Recipient')}</Button> : <Button
                                    type="dashed"
                                    onClick={() => __order.toggleRecipient()}
                                >{i.t('def:Add Recipient')}</Button>}
                            </div>
                        }

                        {__order.item.recipient === 1 &&
                            <>
                                <div className="order__block-item">
                                    <div className="item__heading">{i.t('order:recipient')}</div>
                                </div>

                                <div className="order__block-item">
                                    <div className="item__text">
                                        <Row gutter={[20, 20]} align="middle">
                                            <Col span={5}>
                                                <div className="item__text item__text-bold">{i.t('order:fio')}</div>
                                            </Col>
                                            <Col span={19}>
                                                <div className="item__text">
                                                    {__order.editable ? <>
                                                        <Input
                                                            value={__order.item.recipient_name}
                                                            onChange={e => runInAction(() => __order.item.recipient_name = e.target.value)}
                                                        />
                                                    </> : <>{__order.item.recipient_name}</>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="order__block-item">
                                    <div className="item__text">
                                        <Row gutter={[20, 20]} align="middle">
                                            <Col span={5}>
                                                <div className="item__text item__text-bold">
                                                    {i.t('order:phone')}
                                                </div>
                                            </Col>
                                            <Col span={19}>
                                                <div className="item__text">
                                                    {__order.editable ? <>
                                                        <Input
                                                            value={__order.item.recipient_phone}
                                                            onChange={e => runInAction(() => __order.item.recipient_phone = e.target.value)}
                                                        />
                                                    </> : <>{__order.item.recipient_phone}</>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Col>

                <Col span={24}>
                    <div className="order__block">
                        <div className="order__block-item">
                            <div className="item__heading">
                                <div>{i.t('order:listOrder')}</div>
                                {__order.editable &&
                                    <Button
                                        type="dashed"
                                        onClick={() => __order.setSelect('products')}
                                    >{i.t('def:actions.add')}</Button>
                                }
                            </div>
                        </div>

                        <div className="order__block-item">
                            <OrderProducts
                                items={__order.item.products}
                                editable={__order.editable}
                                setQuantity={(e: any, e1: any, e2: any, e3: any) => __order.setQuantity(e, e1, e2, e3)}
                                deleteProduct={(e: any) => __order.deleteProduct(e, 'products')}
                            />
                        </div>

                        {__order.item.free_products.length > 0 || __order.editable
                          ?
                            <>
                                <Divider />
                                <div className="order__block-item">
                                    <div className="item__heading">
                                        <div>{i.t('order:free_products')}</div>
                                        {__order.editable &&
                                          <Button
                                            type="dashed"
                                            onClick={() => __order.setSelect('free_products')}
                                          >{i.t('def:actions.add')}</Button>
                                        }
                                    </div>
                                </div>
                            </>
                        : <></>}


                        {__order.item.free_products.length > 0
                          ?
                              <div className="order__block-item">
                                  <OrderProducts
                                    items={__order.item.free_products}
                                    editable={__order.editable}
                                    setQuantity={(e: any, e1: any, e2: any, e3: any) => __order.setQuantity(e, e1, e2, e3)}
                                    deleteProduct={(e: any) => __order.deleteProduct(e, 'free_products')}
                                  />
                              </div>
                          : <></>}
                    </div>
                </Col>

                {__order.item?.cutlery || __order.item.comment || __order.editable
                    ?   <Col span={24}>
                      <div className="order__block">
                          <div className="order__block-item">
                              <div className="item__heading">{i.t('order:additionally')}</div>
                          </div>

                          {((__order.item.comment && __order.item.comment !== '') || __order.editable) &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[10, 10]}>
                                        <Col span={24}>
                                            <div className="item__text item__text-bold">{i.t('order:comment')}</div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="item__text">
                                                {__order.editable ? <>
                                                    <Input
                                                      value={__order.item.comment}
                                                      onChange={e => runInAction(() => __order.item.comment = e.target.value)}
                                                    />
                                                </> : <>{__order.item.comment}</>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                          }

                          {(__order.item?.cutlery && __order.item.cutlery >= 0 || __order.editable) &&
                            <div className="order__block-item">
                                <div className="item__text">
                                    <Row gutter={[10, 10]}>
                                        <Col span={24}>
                                            <div className="item__text item__text-bold">{i.t('order:cutlery')}</div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="item__text">
                                                {__order.editable ? <>
                                                    <InputNumber
                                                      value={__order.item?.cutlery ?? 0}
                                                      onChange={e => runInAction(() => __order.item.cutlery = e)}
                                                    />
                                                </> : <>{__order.item.cutlery}</>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                          }
                      </div>
                  </Col>
                    : <></>
                }

                {(__order.item.system_info && __order.item.system_info !== '') &&
                    <Col span={24}>
                        <div className="order__block">
                            <div className="order__block-item">
                                <div className="item__heading">{i.t('order:tech')}</div>
                                <div className="item__text">{__order.item.system_info}</div>
                            </div>
                        </div>
                    </Col>
                }

                {(__order.item.source && __order.item.source !== '') &&
                  <Col span={24}>
                      <div className="order__block">
                          <div className="order__block-item">
                              <div className="item__heading">{i.t('order:source')}</div>
                              <div className="item__text">{__order.item.source}</div>
                          </div>
                      </div>
                  </Col>
                }

                {(__order.item.history && __order.item.history.length > 0) &&
                  <Col span={24}>
                      <div className="order__block">
                          <div className="order__block-item">
                              <div className="item__heading">{i.t('def:History changed')}</div>

                              <Row gutter={[20, 20]}>
                                  {__order.item.history.map((el: any) => <>
                                    <Col span={24}><p className="order_history">{dayjs(el.created_at).format('DD.MM.YYYY HH:mm')} - {el.author} изменил поле {el.field}. Значение ДО <strong>{el.before}</strong> ПОСЛЕ <strong>{el.after}</strong></p></Col>
                                  </>)}
                              </Row>
                          </div>
                      </div>
                  </Col>
                }

            </Row>

            <Modal
                open={__order.select.open}
                width={1300}
                footer={null}
                onCancel={() => __order.setSelect()}
                maskClosable={false}
            >
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col span={6}>
                            <Input
                              placeholder={i.t('search:changeName')}
                              value={__order.filter_list.name ?? ''}
                              onChange={(e) => __order.setFilterProducts('name', e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                              placeholder="ID"
                              value={__order.filter_list.id ?? ''}
                              onChange={(e) => __order.setFilterProducts('id', e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                              placeholder="ID CRM"
                              value={__order.filter_list.id_crm ?? ''}
                              onChange={(e) => __order.setFilterProducts('id_crm', e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Table
                    rowClassName={() => 'editable-row'}
                    columns={columns}
                    dataSource={__order.products}
                    rowKey="id"
                    pagination={{
                        position: ['bottomLeft'],
                        hideOnSinglePage: true,
                        current: __order.pagination.page,
                        pageSize: __order.pagination.limit,
                        total: __order.pagination.total,
                        showSizeChanger: false,
                        onChange: (e) => __order.setProductsPage(e)
                    }}
                />
            </Modal>

            <Modal
                open={__order.usersModal.open}
                width={1000}
                footer={null}
                onCancel={() => __order.setUsers()}
                maskClosable={false}
            >
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <label>{i.t('users:name')}</label>
                        <Input
                          value={__order.usersFilters.name ?? ''}
                          onChange={(e: any) => __order.setUserFilter('name', e.target.value)}
                        />
                    </Col>
                    <Col span={6}>
                        <label>{i.t('users:phone')}</label>
                        <Input
                          value={__order.usersFilters.phone ?? ''}
                          onChange={(e: any) => __order.setUserFilter('phone', e.target.value)}
                        />
                    </Col>
                    <Col span={6}>
                        <label>{i.t('users:email')}</label>
                        <Input
                          value={__order.usersFilters.email ?? ''}
                          onChange={(e: any) => __order.setUserFilter('email', e.target.value)}
                        />
                    </Col>
                </Row>

                <br/>

                <Table
                    rowClassName={() => 'editable-row'}
                    columns={usersColumns}
                    dataSource={__order.users}
                    rowKey="id"
                    pagination={{
                        position: ['bottomLeft'],
                        hideOnSinglePage: true,
                        current: __order.pagination.page,
                        pageSize: __order.pagination.limit,
                        total: __order.pagination.total,
                        showSizeChanger: false,
                        onChange: (e) => __order.setUsersPage(e)
                    }}
                />
            </Modal>

            <Modal
              title="Подтверждение оплаты"
              open={__order.holding_modal.open}
              onOk={() => __order.saveHolding(__order.item.id)}
              onCancel={() => __order.closeModalHolding()}
            >
                <p>{i.t('def:holding_title')}</p>
                <InputNumber
                  value={__order.holding_modal.sum}
                  style={{ width: '100%' }}
                  onChange={onChangeModalSum}
                  decimalSeparator=","
                  min={1}
                  max={__order.item.sum}
                />
            </Modal>
        </>
    )
})