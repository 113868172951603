import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import React, { FormEvent, useEffect } from "react";
import controller from "../../news.controller";
import i from "../../../../translations/i";
import { Titles } from "../../../../layouts/Titles";
import { Button, Col, Divider, Dropdown, Input, Row, Segmented, Select, Space } from "antd";
import { runInAction } from "mobx";
import { SwitchBlock } from "../../../../components/SwitchBlock";
import { WarehousesController } from "../../../settings/warehouses/warehouses.controller";
import types_controller from '../../news.types.controller'
import { DownOutlined } from "@ant-design/icons";
import __designer from "../../../screen_designer/__designer";
import { DesignerIn } from "../../../screen_designer/Designer";
import { DesignerModal } from "../../../screen_designer/designer_modules/DesignerModal";


export const NewsModuleText = observer(() => {

  const blockTypes = [
    { type: "media", screens: ['home', 'home_web', 'catalog'] },
    { type: "products", screens: ['home', 'home_web', 'basket'] },
    { type: "block", screens: ['home', 'home_web'] },
    { type: "search", screens: ['home', 'home_web'] },
    { type: "lastorder", screens: ['home', 'home_web'] },
    { type: "buttons", screens: ['home', 'home_web'] },
    { type: "text", screens: ['home', 'home_web'] }
  ]

  const menuItems: any = blockTypes.map((el: any) => {
    return { label: __designer.getBlockNameByType(el.type), key: el.type }
  })

  const menuProps = {
    items: menuItems,
    onClick: (e: any) => __designer.openModal(e.key)
  }

  return(
    <>
      <Col span={24}>
        <Dropdown
          menu={menuProps}
        >
          <Button type="primary">
            <Space>
              {i.t("constructor:filter.blocks.create")}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <DesignerIn />
      {__designer.modalOpened ? <DesignerModal /> : <></>}

    </>
  )
})