import { makeObservable, observable } from 'mobx'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'
import { openApi } from "../../helpers/request";

class NewsCategoriesController {

  list: any[] = []
  item: any = {
    id: 0,
    enabled: true,
    name: null,
    name_encode: null,
    view_type: 1,
    parent_id: null,
    seo_title: null,
    seo_description: null,
    seo_keywords: null,
  }

  pagination = {
    total: 0,
    limit: 10,
    page: 1,
    offset: 0
  }

  parent_id: null | number = null

  file: any = null

  constructor() {
    makeObservable(this, {
      list: observable,
      item: observable,
      pagination: observable,
      parent_id: observable,
    })
  }

  getList(limit: null | Number = null, tree: boolean = false) {
    limit = limit ? limit : this.pagination.limit

    let path = `/news_categories?limit=${limit}&page=${this.pagination.page}&with[]=image`
    if(this.parent_id) path += `&parent_id=${this.parent_id}`

    if(tree) path += `&tree=${tree}`

    openApi('get', path)
      .then(result => {
        console.log(result.data)
        this.list = result.data
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  getOne(id: any) {
    if(id > 0)
      openApi('get', `/news_categories/${id}?with[]=image`)
        .then(result => {
          console.log(result.data)
          this.item = result.data
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  delete(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/news_categories/${id}`)
        .then(result => {
          this.getList()
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    data.append('enabled', `${+ this.item.enabled}`)
    data.append('name', this.item.name ? `${this.item.name}` : '')
    data.append('name_encode', this.item.name_encode ? `${this.item.name_encode}` : '')
    data.append('view_type', this.item.view_type)
    if(this.item.parent_id) data.append('parent_id', this.item.parent_id)
    if(this.item.seo_title) data.append('seo_title', this.item.seo_title)
    if(this.item.seo_description) data.append('seo_description', this.item.seo_description)
    if(this.item.seo_keywords) data.append('seo_keywords', this.item.seo_keywords)

    if(this.file)
      data.append('image', this.file)

    openApi('post', `/news_categories/${this.item.id}`, data)
      .then(result => {
        if(this.item.id && this.item.id > 0)
          this.getOne(this.item.id)
        else window.location.href = `/news_categories`
      })
      .catch(result => {
        console.log(result.response)
        if(result.response && result.response.data && result.response.data.message)
          notification.error({
            description: result.response.data.message,
            message: i.t('def:errors.error')
          })
      })
  }

  upload(image: any) {
    this.file = image
    return false
  }

}

export default new NewsCategoriesController()