import { observer } from 'mobx-react-lite'
import React, { FormEvent, useEffect } from 'react'
import controller from './controller'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { Button, Col, Input, Row, Segmented, Switch, Modal, Upload, Divider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const intervals = () => {
  let arr = []
  for(let i = 0; i < 24; i++) {
    arr.push({ number: i, string: `${('0' + (`${i}`)).slice(-2)}:00`, seconds: i > 0 ? i * 60 * 60 : 0 })
  }
  return arr
}

export const ExportSchedule = observer(() => {

  useEffect(() => {
    return () => {
      controller.get()
    }
  }, [])



  const days = [
    { title: i.t('def:day.monday'), key: 'monday', value: controller.schedule.monday },
    { title: i.t('def:day.tuesday'), key: 'tuesday', value: controller.schedule.tuesday },
    { title: i.t('def:day.wednesday'), key: 'wednesday', value: controller.schedule.wednesday },
    { title: i.t('def:day.thursday'), key: 'thursday', value: controller.schedule.thursday },
    { title: i.t('def:day.friday'), key: 'friday', value: controller.schedule.friday },
    { title: i.t('def:day.saturday'), key: 'saturday', value: controller.schedule.saturday },
    { title: i.t('def:day.sunday'), key: 'sunday', value: controller.schedule.sunday },
  ]

  return(
    <>
      <form onSubmit={(e: FormEvent) => controller.save(e)}>
        <Titles
          title={i.t('def:Export settings')}
          buttons={[
            { title: i.t('def:actions.back'), href: '/settings', type: 'dashed' },
            { title: i.t('def:Start now'), click: () => controller.now(), type: 'dashed' },
            { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
          ]}
          breadcrumb={[
            { title: i.t('def:settings'), href: '/settings' },
            { title: i.t('def:Export settings') },
          ]}
        />

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <label className="title">{i.t('def:Uri')}</label>
            <p>{controller.schedule.uri ?? ''}</p>
          </Col>
          <Divider />
          <Col span={24}>
            <label className="title">{i.t('def:exportDays')}</label>
            <div className="default-flex">
              {days.map((el, index) => (
                <div
                  key={`schedule_${index}`}
                  className={el.value ? "schedule active" : "schedule"}
                  onClick={() => controller.setDay(el.key, el.value)}
                >
                  <p>{ el.title }</p>
                </div>
              ))}
            </div>
          </Col>
          <Divider />
          <Col span={24}>
            <label className="title">{i.t('def:exportTimes')}</label>
            <p>{i.t('settings:import.serverTime')}</p>

            <div className="default-flex-min">
              {intervals().map((el, index) => (
                <div
                  key={`div_interval_${index}`}
                  className={
                    controller.scheduleDisabledTime.findIndex((o: any) => Number(o) === el.number) > -1
                      ? "schedule disabled"
                      : controller.schedule.time.findIndex((o: any) => Number(o) === el.seconds) > -1 ? "schedule active" : "schedule"}
                  onClick={() => controller.setScheduleTime(el)}
                >
                  <p>{el.string}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </form>
    </>
  )
})