import { makeAutoObservable, runInAction } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../../translations/i'

interface ScheduleInterface {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
  time: number[]
  uri: string
}

class ExportController {

  schedule: ScheduleInterface = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    time: [],
    uri: ''
  }

  scheduleDisabledTime = []

  file: any = null

  rkeeperAccount: any = {}
  rkeeperFields: any[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get() {
    openApi('get', '/export/settings')
      .then(result => {
        console.log(result)
        this.schedule = result.data
      })
  }

  setDay(key: string, value: boolean) {
    console.log(key, value)
    // @ts-ignore
    this.schedule[key] = !value
  }

  setScheduleTime(value: any) {
    console.log(this.schedule.time)
    if(this.scheduleDisabledTime.findIndex(el => el === value.number) > -1) {

    } else {
      if(this.schedule.time.findIndex(el => `${el}` === `${value.seconds}`) > -1) {
        this.schedule.time = this.schedule.time.filter(el => `${el}` !== `${value.seconds}`)
      } else {
        this.schedule.time.push(value.seconds)

      }
      this.reloadDisabledSchedule()
    }
  }

  reloadDisabledSchedule() {
    this.scheduleDisabledTime = []
    this.schedule.time.map(el => {
      const number = el > 0 ? el / 60 / 60 : 0
      // this.setDisabledSchedule([
      //     this.getIndexOfInterval(number, '-', 1),
      //     this.getIndexOfInterval(number, '-', 2),
      //     this.getIndexOfInterval(number, '+', 1),
      //     this.getIndexOfInterval(number, '+', 2),
      // ])
    })
  }

  saveSchedule() {
    const data = new FormData()
    data.append('monday', `${+ this.schedule.monday}`)
    data.append('tuesday', `${+ this.schedule.tuesday}`)
    data.append('wednesday', `${+ this.schedule.wednesday}`)
    data.append('thursday', `${+ this.schedule.thursday}`)
    data.append('friday', `${+ this.schedule.friday}`)
    data.append('saturday', `${+ this.schedule.saturday}`)
    data.append('sunday', `${+ this.schedule.sunday}`)
    data.append('time', JSON.stringify(this.schedule.time))

    this.scheduleDisabledTime = []

    openApi('post', '/export/settings', data)
      .then(result => {
        window.location.reload()
      })
      .catch(err => console.log(err.response))
  }

  save(e: FormEvent) {
    e.preventDefault()
    this.saveSchedule()

  }

  now() {
    openApi('post', '/export/now')
      .then(result => {
        console.log(result)
        notification.success({
          message: i.t('def:success.complete'),
          description: i.t('def:Export complete')
        });
      })
      .catch(err => console.log(err.response))
  }


}

export default new ExportController()