import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import __designer from '../__designer'
import { Button, Col, ColorPicker, Input, Modal, Row, Select, Upload, Divider } from 'antd'
import { __cpl } from '../../catalog/custom_product_lists/__cpl'
import { SwitchBlock } from '../../../components/SwitchBlock'
import { InboxOutlined } from '@ant-design/icons'
import { SelectEntity } from '../../../helpers/select_entity/SelectEntity'
import __select from '../../../helpers/select_entity/__select.controller'
import { CustomProductListsLayout } from '../../catalog/custom_product_lists/CustomProductListsLayout'
import React, { FormEvent } from "react"
import { TextEditor } from "../../../helpers/TextEditor";

export const DesignerModal = observer(() => {


    return (
        <Modal
            title={i.t('constructor:editBlock')}
            open={__designer.modalOpened}
            footer={null}
            onCancel={() => __designer.closeModal()}
            width={1000}
            centered={true}
            maskClosable={false}
        >
            <form onSubmit={(e: FormEvent) => __designer.save(e)}>
                <Row gutter={[20, 20]}>
                    {__cpl.selectedList === null &&
                        <Col span={24}>
                            <SwitchBlock array={[{
                                label: i.t('def:active'),
                                click: (e: any) => __designer.item.enabled = e,
                                value: __designer.item.enabled
                            }]} />
                        </Col>
                    }

                    {['products', 'block', 'story', 'media', 'video', 'banner', 'text'].includes(__designer.selectedType) &&
                        <Col span={24}>
                            <label className="formLabel" htmlFor="name">
                                {['block'].includes(__designer.selectedType) ? i.t('def:Heading') : i.t('def:name')}
                            </label>
                            <Input
                                id="name"
                                value={__designer.item.element.name}
                                onChange={e => __designer.item.element.name = e.target.value}
                            />
                        </Col>
                    }

                    {['onboarding'].includes(__designer.selectedType) &&
                        <Col span={12}>
                            <label className="formLabel" htmlFor="name">
                                {i.t('def:Heading')}
                            </label>
                            <Input
                                id="name"
                                value={__designer.item.element.label}
                                onChange={e => __designer.item.element.label = e.target.value}
                            />
                        </Col>
                    }

                    {['onboarding'].includes(__designer.selectedType) &&
                        <>
                            <Col span={12}>
                                <label className="formLabel">{i.t('def:Block Heading Color')}</label>
                                <ColorPicker
                                    showText
                                    value={`#${__designer.item.element.label_color}`}
                                    onChange={e => __designer.item.element.label_color = e.toHex()}
                                />
                            </Col>
                            <Col span={12}>
                                <label className="formLabel" htmlFor="label">{i.t('def:Text')}</label>
                                <Input
                                    id="label"
                                    value={__designer.item.element.text}
                                    onChange={(e) => __designer.item.element.text = e.target.value}
                                />
                            </Col>

                            <Col span={12}>
                                <label className="formLabel">{i.t('def:Text Color')}</label>
                                <ColorPicker
                                    showText
                                    value={`#${__designer.item.element.text_color}`}
                                    onChange={e => __designer.item.element.text_color = e.toHex()}
                                />
                            </Col>
                        </>
                    }

                    {['block', 'story_element'].includes(__designer.selectedType) &&
                        <>
                            <Col span={12}>
                                <label
                                    className="formLabel"
                                    htmlFor="label"
                                >
                                    {__designer.selectedType === 'block' ? i.t('def:Text') : i.t('def:Block Heading')}
                                </label>
                                <Input
                                    id="label"
                                    value={__designer.item.element.label}
                                    maxLength={255}
                                    onChange={(e) => __designer.item.element.label = e.target.value}
                                />
                            </Col>


                            {__designer.selectedType === 'story_element' &&
                                <>
                                    <Col span={12}>
                                        <label className="formLabel">{i.t('def:Block Heading Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.label_color}`}
                                            onChange={e => __designer.item.element.label_color = e.toHex()}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel" htmlFor="label">{i.t('def:Text')}</label>
                                        <Input
                                            id="label"
                                            value={__designer.item.element.text}
                                            onChange={(e) => __designer.item.element.text = e.target.value}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel">{i.t('def:Text Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.text_color}`}
                                            onChange={e => __designer.item.element.text_color = e.toHex()}
                                        />
                                    </Col>
                                </>
                            }

                            {__designer.selectedType === 'story_element' &&
                                <>
                                    <Col span={24}>
                                        <SwitchBlock array={[{
                                            label: i.t('def:Show Button'),
                                            click: (e: any) => __designer.item.element.button = e,
                                            value: __designer.item.element.button
                                        }]} />
                                    </Col>
                                </>
                            }

                            {(__designer.item.element.button || __designer.selectedType === 'block') &&
                                <>
                                    <Col span={12}>
                                        <label
                                            className="formLabel"
                                            htmlFor="button_text"
                                        >
                                            {i.t('def:buttonText')}
                                        </label>
                                        <Input
                                            id="button_text"
                                            value={__designer.item.element.button_text}
                                            onChange={(e) => __designer.item.element.button_text = e.target.value}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel" >{i.t('def:Button Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.button_color}`}
                                            onChange={e => __designer.item.element.button_color = e.toHex()}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel">{i.t('def:Button Text Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.button_text_color}`}
                                            onChange={e => __designer.item.element.button_text_color = e.toHex()}
                                        />
                                    </Col>
                                </>
                            }
                        </>
                    }

                    {['banner', 'video'].includes(__designer.selectedType) &&
                        <Col span={24}>
                            <label className="formLabel" htmlFor="label">
                                {__designer.selectedType === 'video' ?
                                    <>{i.t('def:Video Link')}*</> : <>{i.t('def:Text')}</>
                                }
                            </label>
                            <Input
                                id="label"
                                value={__designer.item.element.text}
                                onChange={(e) => __designer.item.element.text = e.target.value}
                            />
                        </Col>
                    }

                    {['story', 'story_element', 'video', 'banner', 'onboarding'].includes(__designer.selectedType) &&
                        <>
                            {(__designer.selectedType === 'story_element' || __designer.selectedType === 'onboarding')
                                ? <>

                                  <Divider />

                                  <Col span={24}>
                                      <h4>{i.t('def:Upload image or video')}</h4>
                                  </Col>

                                  <Col span={12}>
                                      <label className="formLabel">{i.t('def:image.upload')}</label>
                                      <Upload
                                        multiple={false}
                                        maxCount={1}
                                        beforeUpload={(e: any) => {
                                            let reader = new FileReader()
                                            reader.readAsDataURL(e)
                                            reader.onloadend = function () {
                                                e.url = reader.result
                                                __designer.item.element.file = [e]
                                            }

                                            return false
                                        }}
                                        onRemove={() => {
                                            __designer.item.element.file = []
                                        }}
                                        fileList={__designer.item.element.file.filter((el: any) => el.type_file !== 'video')}
                                        showUploadList={true}
                                        listType="picture-card"
                                      >
                                          {/*<Button icon={<InboxOutlined />}>{i.t('def:actions.upload')}</Button>*/}
                                          <p>{i.t('def:actions.upload')}</p>
                                      </Upload>
                                  </Col>

                                  <Col span={12}>
                                      <label className="formLabel">{i.t('def:upload_video')}</label>
                                      <Input
                                        value={(__designer.item.element.file[0] && __designer.item.element.file[0].type_file && __designer.item.element.file[0].type_file === 'video') ? __designer.item.element.file[0].uri : ''}
                                        onChange={(e: any) => {
                                            __designer.item.element.file = [{
                                                uri: e.target.value,
                                                type_file: 'video'
                                            }]
                                        }}
                                      />
                                  </Col>
                                  <Divider />
                              </>
                                :  <Col span={24}>
                                  <label className="formLabel">{i.t('def:image.upload')}*</label>
                                  <Upload
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={(e: any) => {
                                        let reader = new FileReader()
                                        reader.readAsDataURL(e)
                                        reader.onloadend = function () {
                                            e.url = reader.result
                                            __designer.item.element.file = [e]
                                        }

                                        return false
                                    }}
                                    onRemove={() => {
                                        __designer.item.element.file = []
                                    }}
                                    fileList={__designer.item.element.file}
                                    showUploadList={true}
                                    listType="picture-card"
                                  >
                                      <Button icon={<InboxOutlined />}>{i.t('def:actions.upload')}</Button>
                                  </Upload>
                              </Col>
                            }
                        </>
                    }

                    {__designer.selectedType === 'text' &&
                        <>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:text')}</label>

                               <TextEditor
                                 content={__designer.item.element.value}
                                 returned={(e: any) => __designer.item.element.value = e}
                               />

                            </Col>
                        </>
                    }


                    {__designer.selectedType === 'media' &&
                        <>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:Media Type')}</label>
                                <Select
                                    style={{ width: `100%` }}
                                    options={[
                                        { label: i.t('def:Banners'), value: 'banner' },
                                        { label: i.t('def:Stories'), value: 'story' },
                                        { label: i.t('def:Video'), value: 'video' }
                                    ]}
                                    onChange={(e) =>__designer.item.element.type = e}
                                    value={__designer.item.element.type}
                                />
                            </Col>

                            {['banner', 'video'].includes(__designer.item.element.type) &&
                                <Col span={24}>
                                    <label className="formLabel">{i.t('constructor:config.videoCategory.type')}</label>
                                    <Select
                                        style={{ width: `100%` }}
                                        options={[
                                            { label: i.t('constructor:config.slider.items.one'), value: 1  },
                                            { label: i.t('constructor:config.slider.items.two'), value: 2  },
                                            { label: i.t('constructor:config.slider.items.three'), value: 3  },
                                            { label: i.t('constructor:config.slider.items.four'), value: 4  },
                                            { label: i.t('constructor:config.slider.items.five'), value: 5 },
                                            { label: i.t('constructor:config.slider.items.six'), value: 6 },
                                            { label: i.t('constructor:config.slider.items.seven'), value: 7 },
                                            { label: i.t('constructor:config.slider.items.eight'), value: 8 },
                                            { label: i.t('constructor:config.slider.items.nine'), value: 9 },
                                            { label: i.t('constructor:config.slider.items.ten'), value: 10 },
                                            { label: i.t('constructor:config.slider.items.eleven'), value: 11 },
                                            { label: i.t('constructor:config.slider.items.twelve'), value: 12 },
                                            { label: i.t('constructor:config.slider.items.thirteen'), value: 13 },
                                            { label: i.t('constructor:config.slider.items.fourteen'), value: 14 },
                                            { label: i.t('constructor:config.slider.items.fifteen'), value: 15 }
                                        ]}
                                        onChange={(e) => __designer.item.element.view_type = e}
                                        value={__designer.item.element.view_type}
                                    />
                                </Col>
                            }

                            {__designer.item.element.type === 'story' &&
                                <Col span={24}>
                                    <label className="formLabel">{i.t('constructor:config.storyBlock.type')}</label>
                                    <Select
                                        style={{ width: `100%` }}
                                        options={[
                                            { label: i.t('constructor:config.storyBlock.items.one'), value: 1 },
                                            { label: i.t('constructor:config.storyBlock.items.two'), value: 2 }
                                        ]}
                                        onChange={(e) => __designer.item.element.view_type = e}
                                        value={__designer.item.element.view_type}
                                    />
                                </Col>
                            }
                        </>

                    }

                    {__designer.selectedType === 'story' &&
                        <Col span={24}>
                            <label className="formLabel">{i.t('constructor:config.story.type')}</label>
                            <Select
                                style={{ width: `100%` }}
                                options={[
                                    { label: i.t('constructor:config.story.items.one'), value: 1 },
                                    { label: i.t('constructor:config.story.items.two'), value: 2 },
                                    { label: i.t('constructor:config.story.items.three'), value: 3 },
                                    { label: i.t('constructor:config.story.items.four'), value: 4 }
                                ]}
                                onChange={(e) => __designer.item.element.type = e}
                                value={__designer.item.element.type}
                            />
                        </Col>
                    }

                    {(__designer.selectedType === 'story_element' || __designer.selectedType === 'onboarding') &&
                        <Col span={24}>
                            <label className="formLabel">{i.t('constructor:config.storySlide.typeImage')}</label>
                            <Select
                                style={{ width: `100%` }}
                                options={[
                                    { label: i.t('constructor:config.storySlide.items.one'), value: 1 },
                                    { label: i.t('constructor:config.storySlide.items.two'), value: 2 }
                                ]}
                                onChange={(e) => __designer.item.element.display_image = e}
                                value={__designer.item.element.display_image}
                            />
                        </Col>
                    }

                    {/*{__designer.selectedType === 'story_element' &&*/}
                    {/*  <Col span={24}>*/}
                    {/*      <label className="formLabel">{i.t('constructor:config.storySlide.typeImage')}</label>*/}
                    {/*      <Select*/}
                    {/*        style={{ width: `100%` }}*/}
                    {/*        options={[*/}
                    {/*            { label: i.t('constructor:config.storySlide.items.one'), value: 1 },*/}
                    {/*            { label: i.t('constructor:config.storySlide.items.two'), value: 2 }*/}
                    {/*        ]}*/}
                    {/*        onChange={(e) => __designer.item.element.imagedisplaystory = e}*/}
                    {/*        value={__designer.item.element.imagedisplaystory}*/}
                    {/*      />*/}
                    {/*  </Col>*/}
                    {/*}*/}

                    {__designer.selectedType === 'onboarding' &&
                        <>
                            <Col span={24}>
                                <SwitchBlock array={[{
                                    label: i.t('def:Show Button'),
                                    click: (e: any) => __designer.item.element.button = e,
                                    value: __designer.item.element.button
                                }]} />
                            </Col>

                            {Boolean(__designer.item.element.button) &&
                                <>
                                    <Col span={12}>
                                        <label
                                            className="formLabel"
                                            htmlFor="button_text"
                                        >
                                            {i.t('def:buttonText')}
                                        </label>
                                        <Input
                                            id="button_text"
                                            value={__designer.item.element.button_text}
                                            onChange={(e) => __designer.item.element.button_text = e.target.value}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel" >{i.t('def:Button Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.button_color}`}
                                            onChange={e => __designer.item.element.button_color = e.toHex()}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <label className="formLabel">{i.t('def:Button Text Color')}</label>
                                        <ColorPicker
                                            showText
                                            value={`#${__designer.item.element.button_text_color}`}
                                            onChange={e => __designer.item.element.button_text_color = e.toHex()}
                                        />
                                    </Col>
                                </>
                            }
                        </>
                    }

                    {['block', 'banner', 'story_element', 'onboarding'].includes(__designer.selectedType) &&
                        <>
                            {(!__designer.isOnBoarding) &&
                                <Col span={24}>
                                    <SelectEntity
                                        type={__select.select.type}
                                        element={__select.select.value}
                                        item_element={__select.select.element}
                                    />
                                </Col>
                            }
                        </>
                    }

                    {__designer.selectedType === 'products' &&
                        <>
                            <Col span={24}>
                                <label className="formLabel">{i.t('constructor:config.blockProduct.type')}</label>
                                <Select
                                    style={{ width: `100%` }}
                                    options={[
                                        { label: i.t('constructor:config.blockProduct.items.one'), value: 1 },
                                        { label: i.t('constructor:config.blockProduct.items.two'), value: 2 }
                                    ]}
                                    onChange={(e) => __designer.item.element.view_type = e}
                                    value={__designer.item.element.view_type}
                                />
                            </Col>

                            <Col span={24}>
                                <CustomProductListsLayout
                                    type="group"
                                    multiple={false}
                                    selectedElements={[__designer.item.element.list]}
                                />
                            </Col>
                        </>
                    }

                    {__cpl.selectedList === null &&
                        <Col span={24}>
                            <Button htmlType="submit" type="primary" shape="round">{i.t('def:actions.save')}</Button>
                        </Col>
                    }
                </Row>
            </form>
        </Modal>
    )
})