import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { Row, Col, Divider, Input, Button } from 'antd'
import { __group } from './__group'
import { SwitchBlock } from '../../../components/SwitchBlock'
import { CustomProductListsLayout } from '../custom_product_lists/CustomProductListsLayout'
import { __cpl } from '../custom_product_lists/__cpl'
import { CloseOutlined } from '@ant-design/icons'

export const Group = observer(() => {

    const { groupID }: any = useParams()

    useEffect(() => {
        if(groupID > 0) {
            __group.getOne(groupID)
        }
    }, [])

    return <>
        {__cpl.selectedList === null &&
            <form onSubmit={(e) => __group.sendForm(e)}>
                <Titles
                    title={i.t('def:Edit Group')}
                    breadcrumb={[
                        { title: i.t('def:Product Groups'), href: `/catalog/groups` }
                    ]}
                    buttons={[
                        { title: i.t('def:actions.back'), href: `/catalog/groups`, type: 'dashed' },
                        { title: i.t('def:actions.save'), htmlType: 'submit', type: 'primary' }
                    ]}
                />
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <SwitchBlock array={[
                            { label: i.t('def:active'), click: (e: any) => __group.item.enabled = e, value: __group.item.enabled },
                        ]} />
                    </Col>
                    <Col span={12}>
                        <label className="formLabel">{i.t('def:name')}</label>
                        <Input
                            value={__group.item.name}
                            onChange={ (e) => runInAction(() =>__group.item.name = e.target.value) }
                        />
                    </Col>
                    <Col span={12}>
                        <label className="formLabel">{i.t('def:id_crm')}</label>
                        <Input
                            value={__group.item.id_crm}
                            onChange={ (e) => runInAction(() =>__group.item.id_crm = e.target.value) }
                        />
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>

                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <label className="formLabel">{i.t('def:Tags')}</label>
                            </Col>
                            <Col span={24}>
                                <p className="tags_p">
                                    {__group.item.tags.map((tag: string, index: number) => (
                                      <>
                                          <span className="tag" onClick={() => __group.deleteTag(tag)}>{tag}</span>
                                      </>
                                    ))}

                                </p>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>
                                        <Input
                                          value={__group.new_tag ?? ''}
                                          placeholder={i.t('def:Add tag')}
                                          onChange={(e: any) => __group.new_tag = e.target.value}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                          onClick={() => __group.addTag()}
                                          disabled={!__group.new_tag}
                                        >
                                            {i.t("def:Add tag")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/><br/>


                    </Col>
                </Row>
            </form>
        }

        <CustomProductListsLayout selectedElements={__group.item.conditions}/>
    </>
})