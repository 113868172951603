import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import __designer from './__designer'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import {Button, Col, Dropdown, Input, Modal, Popconfirm, Row, Segmented, Select, Space, Avatar, ColorPicker, Upload, Image, Switch} from 'antd'
import {ArrowDownOutlined, ArrowUpOutlined, VideoCameraOutlined, DownOutlined, EditOutlined, InboxOutlined, UnorderedListOutlined} from '@ant-design/icons'
import { WarehousesController } from '../settings/warehouses/warehouses.controller'
import { ActionsConstructor } from './designer_modules/ActionsConstructor'
import { DesignerModal } from './designer_modules/DesignerModal'

const Storage = new WarehousesController()

export const Designer = observer(() => {

    useEffect(() => {
        Storage.pagination.limit = 999
        Storage.get()
        __designer.getList()
    }, [])

    const screensTypes = [
        { label: i.t("constructor:main"), value: 'home' },
        { label: i.t("constructor:main_web"), value: 'home_web' },
        { label: i.t("constructor:cart"), value: 'basket' },
        { label: i.t("constructor:catalog"), value: 'catalog' },
    ]

    const blockTypes = [
        { type: "media", screens: ['home', 'home_web', 'catalog'] },
        { type: "products", screens: ['home', 'home_web', 'basket'] },
        { type: "block", screens: ['home', 'home_web'] },
        { type: "search", screens: ['home', 'home_web'] },
        { type: "lastorder", screens: ['home', 'home_web'] },
        { type: "buttons", screens: ['home', 'home_web'] },
        { type: "text", screens: ['home', 'home_web'] }
    ]

    const menuItems: any = blockTypes.map((el: any) => {
        if(el.screens.findIndex((i: any) => i === __designer.place) > -1 && !el.disabled){
            return { label: __designer.getBlockNameByType(el.type), key: el.type }
        }
    })

    const menuProps = {
        items: menuItems,
        onClick: (e: any) => __designer.openModal(e.key)
    }

    return <>
        <Titles title={i.t('constructor:title')} />

        <Row gutter={[20, 20]} className="designer">
            <Col span={12}>
                <Segmented
                    value={__designer.place}
                    options={screensTypes.map((el: any) => ({ label: el.label, value: el.value }))}
                    onChange={(e: any) => __designer.setPlace(e)}
                />
            </Col>
            <Col span={12}>
                <Row gutter={[10,10]} justify="end">
                    <Col>
                        <Select
                            value={__designer.selectedWarehouse > 0 ? __designer.selectedWarehouse : null}
                            style={{ width: "250px" }}
                            onChange={(e) => __designer.setWarehouse(e)}
                        >
                            <Select.Option value={null}>{i.t("constructor:storages")}</Select.Option>
                            {Storage.list.map((item: any) => (
                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col>
                        <Dropdown
                            menu={menuProps}
                        >
                            <Button type="primary">
                                <Space>
                                    {i.t("constructor:filter.blocks.create")}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="designer__blocks">
                <DesignerIn />
            </Col>
        </Row>

        {__designer.modalOpened ? <DesignerModal /> : <></>}
    </>
})


export const DesignerIn = observer(() => {

    return(
      <>
          <Row gutter={[10, 10]}>
              {__designer.list.length > 0 && __designer.list.map((item: any, index: number) => {
                  //if(item.type === 'search') return <></>
                  let actions = [
                      {type: 'edit', onClick: () => __designer.openModal(item.type, item.sorting_id)},
                      {type: 'moveUp', onClick: () => __designer.sortBlock('up', item.sorting_id)},
                      {type: 'moveDown', onClick: () => __designer.sortBlock('down', item.sorting_id)},
                      {type: 'delete', onClick: () => __designer.deleteOne(item.type, item.element?.id ?? item.id)}
                  ]

                  if(item.type === 'media') {
                      actions = [{type: 'editElements', onClick: () => __designer.toggleBlock(item.sorting_id, 'block')}].concat(actions)
                  }

                  // @ts-ignore
                  actions = [{type: 'switch', onClick: () => __designer.toggleActive(item.type, item.sorting_id), value: item.enabled}].concat(actions)

                  let addText = ''
                  let opened = __designer.openedBlocks.block.findIndex((el: any) => el === item.sorting_id) > -1
                  let className = 'designer__block'

                  if(!['search', 'lastorder', 'buttons'].includes(item.type)) {
                      if(opened) {
                          className += ' designer__block-opened'
                      }
                      if(item.element && item.element.type && item.element.type === 'story') {
                          addText = i.t('def:Add Story')
                      } else if(item.element && item.element.type && item.element.type === 'video') {
                          addText = i.t('def:Add Video')
                      } else if(item.element && item.element.type && item.element.type === 'banner') {
                          addText = i.t('def:Add Banner')
                      } else {
                          addText = i.t('def:actions.add')
                      }
                  }

                  return <Col span={24} key={index}>
                      <div className={className}>
                          <div className="block__title">
                              <div className="block__name">{__designer.getBlockNameByType(item.type, item.element?.type ?? '')} #{item.id}
                                  {item.element?.name ? ` | ${item.element.name}` : ''}</div>
                              {['banner', 'video'].includes(item.element?.type) &&
                                <div className="block__type">{__designer.getBannerViewTypeName(item.element.view_type)}</div>
                              }
                              {item.element?.type === 'story' &&
                                <div className="block__type">{__designer.getStoryViewTypeName(item.element.view_type)}</div>
                              }
                              {item?.type === 'products' &&
                                <div className="block__type">{__designer.getProductsViewTypeName(item.element.view_type)}</div>
                              }
                          </div>
                          <ActionsConstructor actions={actions} />

                          {opened &&
                            <div className="block__elements">
                                <Row gutter={[10, 10]}>
                                    <Col span={24}>
                                        <Button
                                          type="primary"
                                          shape="round"
                                          onClick={() => __designer.openModal(item.element.type, item.id, 0, item.element.id)}
                                        >
                                            {addText}
                                        </Button>
                                    </Col>
                                    {item.element.items.map((el: any, key: number) => {
                                        let actions = [
                                            {type: 'edit', onClick: () => __designer.openModal(item.element.type, item.sorting_id, el.id, item.element.id)},
                                            {type: 'moveUp', onClick: () => __designer.sortBlock('up', el.id, 'media', item.element.id)},
                                            {type: 'moveDown', onClick: () => __designer.sortBlock('down', el.id, 'media', item.element.id)},
                                            {type: 'delete', onClick: () => __designer.deleteOne('element', el.id, true)}
                                        ]

                                        if(item.element.type === 'story') {
                                            actions = [{type: 'editElements', onClick: () => __designer.toggleBlock(el.id, item.element.type)}].concat(actions)
                                        }

                                        // @ts-ignore
                                        actions = [{type: 'switch', onClick: () => __designer.toggleActive(item.element.type, item.sorting_id, el.id, item.element.id), value: el.enabled}].concat(actions)

                                        let opened = __designer.openedBlocks?.[item.element.type]?.findIndex((e: any) => e === el.id) > -1
                                        let className = 'designer__block designer__block-withImage'
                                        if(opened) {
                                            className += ' designer__block-opened'
                                        }

                                        let image_type = null

                                        let image = el?.file?.uri
                                        if(image) {
                                            image = `${el.file.base}${el.file.uri}`
                                            image_type = el.file.type
                                        }

                                        return <Col span={24} key={key}>
                                            <div className={className}>
                                                <div className="block__image">
                                                    {
                                                        image
                                                          ? <>
                                                              {image_type === 'video'
                                                                ? <>
                                                                    <VideoCameraOutlined />
                                                                </>
                                                                : <Image
                                                                  src={image}
                                                                  alt={el?.name ?? ''}
                                                                  preview={{
                                                                      toolbarRender: () => <></>
                                                                  }}
                                                                />
                                                              }
                                                          </>
                                                          : <></>
                                                    }
                                                </div>
                                                <div
                                                  className="block__title"
                                                >
                                                    <div className="block__name">
                                                        #{el.id}{el?.name ? ` | ${el.name}` : ''}
                                                    </div>

                                                    {item?.element?.type === 'story' ? (<>
                                                        <div className="block__type">{__designer.getStoryElementViewTypeName(el.type)}</div>
                                                    </>) : (<>
                                                        {el.refer?.type &&
                                                          <div className="block__refer">
                                                              {__designer.getReferNameByType(el.refer.type)}
                                                          </div>
                                                        }
                                                    </>)}
                                                </div>

                                                <ActionsConstructor actions={actions} />

                                                {opened &&
                                                  <div className="block__elements">
                                                      <Row gutter={[10, 10]}>
                                                          <Col span={24}>
                                                              <Button
                                                                type="primary"
                                                                shape="round"
                                                                onClick={() => __designer.openModal('story_element', el.id, 0, item.sorting_id)}
                                                              >
                                                                  {i.t('def:Add Slide')}
                                                              </Button>
                                                          </Col>
                                                          {item.element.items[key].stories.map((story: any, keyIndex: number) => {
                                                              let actions = [
                                                                  {
                                                                      type: 'edit',
                                                                      onClick: () => __designer.openModal('story_element', el.id, story.id, item.sorting_id)
                                                                  },
                                                                  {
                                                                      type: 'moveUp',
                                                                      onClick: () => __designer.sortBlock('up', story.id, 'story', el.id)
                                                                  },
                                                                  {
                                                                      type: 'moveDown',
                                                                      onClick: () => __designer.sortBlock('down', story.id, 'story', el.id)
                                                                  },
                                                                  {
                                                                      type: 'delete',
                                                                      onClick: () => __designer.deleteOne('story', story.id, true)
                                                                  }
                                                              ]

                                                              // @ts-ignore
                                                              actions = [{type: 'switch', onClick: () => __designer.toggleActive('story_element', el.id, story.id, item.sorting_id), value: story.enabled}].concat(actions)

                                                              let image_type = null
                                                              let image = story?.file?.uri
                                                              if (image) {
                                                                  image = `${story.file.base}${story.file.uri}`
                                                                  image_type = story.file.type
                                                              }

                                                              return <Col span={24} key={keyIndex}>
                                                                  <div className="designer__block designer__block-withImage">
                                                                      <div className="block__image">
                                                                          {
                                                                              image
                                                                                ? <>
                                                                                    {image_type === 'video'
                                                                                      ? <>
                                                                                          <Avatar icon={<VideoCameraOutlined />} />
                                                                                      </>
                                                                                      : <Image
                                                                                        src={image}
                                                                                        alt={story?.name ?? ''}
                                                                                        preview={{
                                                                                            toolbarRender: () => <></>
                                                                                        }}
                                                                                      />
                                                                                    }
                                                                                </>
                                                                                : <></>
                                                                          }
                                                                      </div>
                                                                      <div
                                                                        className="block__title"
                                                                      >
                                                                          <div className="block__name">
                                                                              #{story.id}{story?.label ? ` | ${story.label}` : (story?.text ? ` | ${story.text}` : '')}
                                                                          </div>

                                                                          <div className="block__refer">
                                                                              {story.refer?.type &&
                                                                                <>{__designer.getReferNameByType(story.refer.type)}</>
                                                                              }
                                                                          </div>
                                                                      </div>
                                                                      <ActionsConstructor actions={actions} />
                                                                  </div>
                                                              </Col>
                                                          })}
                                                      </Row>
                                                  </div>
                                                }
                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </div>
                          }
                      </div>
                  </Col>
              })}
          </Row>
      </>
    )
})
