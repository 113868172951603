import { makeAutoObservable } from 'mobx'
import { openApi } from '../../helpers/request'

export class integrationsController {

    list: any[] = []
    element: any = {}
    visible = false

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        openApi('get', '/integrations')
            .then(result => {
                console.log(result)
                this.list = result.data
            })
            .catch(error => {})
    }

    getOne(integration_id: string) {
        openApi('get', `/integrations/${integration_id}`)
            .then(result => {
                console.log(result)
                this.visible = true
                this.element = result.data
            })
            .catch(error => {})
    }

    getCode(integration_id: string) {
        openApi('get', `/integrations/${integration_id}/code`)
            .then(result => {
                console.log(result)
                this.element.code = result.data
            })
            .catch(error => {})
    }

    refreshClientId(integration_id: string) {
        openApi('put', `/integrations/${integration_id}/client`)
            .then(result => {
                this.getOne(integration_id)
            })
            .catch(error => {})
    }

    set(name: string, value: any) {
        this.element[name] = value
    }

    close() {
        this.visible = false
        this.element = {}
    }

    create() {
        this.visible = true
    }

    save() {
        if(this.element.name && this.element.description && this.element.name !== '' && this.element.description !== '') {

            if(this.element.integration_id && this.element.integration_id !== '') {
                const data = {name: this.element.name, description: this.element.description}
                openApi('put', `/integrations/${this.element.integration_id}`, data)
                    .then(result => {
                        this.close()
                        this.getList()
                    })
            } else {
                const data = new FormData()
                data.append('name', this.element.name)
                data.append('description', this.element.description)
                openApi('post', `/integrations/create`, data)
                    .then(result => {
                        this.close()
                        this.getList()
                    })
            }
        } else {
            // Error
            return false
        }
    }

    delete(integration_id: number) {
        openApi('delete', `/integrations/${integration_id}`)
            .then(result => {
                this.getList()
            })
    }

}