import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Pagination,
  List,
  Tooltip,
  Alert,
  Upload,
  Tabs,
  TreeSelect, Divider
} from "antd";
import { DeleteOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import getLoader from "../../../../store/uploadFiles.controller";
import React, { useEffect } from "react";
import categoryController from "../controllers/category.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import pc from "../../properties/controllers/properties.controller";
import i from '../../../../translations/i'
import { Editor } from 'primereact/editor'
import { runInAction } from "mobx";
import { deleteFile } from "../../../../helpers/files.controller";
import __designer from "../../../../front/screen_designer/__designer";
import ReactQuill from "react-quill";

const ShopCategoryEditedMain = observer(() => {

    const header = (
        <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
    </span>
    )

    return(
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Checkbox
                        checked={Number(categoryController.category.view) > 0 ? true : false}
                        onChange={(e) => categoryController.setCategory("view", e.target.checked ? 1 : 0)}
                    >
                        {i.t('category:active')}
                    </Checkbox>
                </Col>
                <Col span={12} className="gutter-row">
                    <label htmlFor="name" className="all-label">{i.t('category:name')}</label>
                    <Input
                        value={categoryController.category.name}
                        id="name"
                        onChange={(e) => categoryController.setCategory("name", e.target.value)}
                        required
                    />
                </Col>
                <Col span={12} className="gutter-row">
                    <label htmlFor="name" className="all-label">{i.t('def:id_crm')}</label>
                    <Input
                        value={categoryController.category.id_crm}
                        id="id_crm"
                        onChange={(e) => categoryController.setCategory("id_crm", e.target.value)}
                        required
                    />
                </Col>
                <Col span={24}>
                  <label className="formLabel">{i.t('product:parent')}</label>
                  {categoryController.tree.length
                    ? <TreeSelect
                      style={{ width: '100%' }}
                      treeData={[{ value: 0, title: "" }, ...categoryController.tree.filter((el) => el.value !== categoryController.category.id)]}
                      // treeData={categoryController.tree.filter((el) => el.value !== categoryController.category.id)}
                      value={categoryController.category.parentid > 0 ? categoryController.category.parentid : null}
                      onChange={(e) => {
                        console.log(e)
                        runInAction(() => categoryController.category.parentid = e)}
                      }
                      showSearch={false}
                    />
                    : <></>
                  }
                </Col>
              <Divider />

                <Col span={6}>
                  <label className="formLabel">{i.t('def:seo.name_encode')}</label>
                  <Input
                    value={categoryController.category.name_encode}
                    onChange={ (e) => runInAction(() => {
                      categoryController.category.name_encode = e.target.value.replace(/[^a-zA-Z0-9_]/g, '')
                    }) }
                  />
                </Col>
                <Col span={6}>
                  <label className="formLabel">{i.t('def:seo.title')}</label>
                  <Input
                    value={categoryController.category.seo_title}
                    onChange={ (e) => runInAction(() => categoryController.category.seo_title = e.target.value) }
                  />
                </Col>
                <Col span={6}>
                  <label className="formLabel">{i.t('def:seo.description')}</label>
                  <Input
                    value={categoryController.category.seo_description}
                    onChange={ (e) => runInAction(() => categoryController.category.seo_description = e.target.value) }
                  />
                </Col>
                <Col span={6}>
                  <label className="formLabel">{i.t('def:seo.keywords')}</label>
                  <Input
                    value={categoryController.category.seo_keywords}
                    onChange={ (e) => runInAction(() => categoryController.category.seo_keywords = e.target.value) }
                  />
                </Col>


              <Divider />
                <Col span={24} className="gutter-row">
                    <label htmlFor="text" className="all-label">{i.t('category:description')}</label>

                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['clean']
                      ],
                    }}
                    formats={['header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent']}
                    value={categoryController.category.text ?? ''}
                    onChange={(e) => categoryController.category.text = e}
                  />

                    {/*<BraftEditor*/}
                    {/*    value={categoryController.category.text}*/}
                    {/*    onChange={(e) => categoryController.setCategory('text', e.toHTML())}*/}
                    {/*    placeholder={i.t('category:description')}*/}
                    {/*    language="ru"*/}
                    {/*    controls={[*/}
                    {/*        'bold', 'italic', 'underline'*/}
                    {/*    ]}*/}
                    {/*/>*/}
                </Col>
              <Col span={24}>

                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <label className="formLabel">{i.t('def:Tags')}</label>
                  </Col>
                  <Col span={24}>
                    <p className="tags_p">
                      {categoryController.category.tags && categoryController.category.tags.map((tag, index) => (
                        <>
                          <span className="tag" onClick={() => categoryController.deleteTag(tag)}>{tag}</span>
                        </>
                      ))}

                    </p>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <Input
                          value={categoryController.new_tag ?? ''}
                          placeholder={i.t('def:Add tag')}
                          onChange={(e) => categoryController.new_tag = e.target.value}
                        />
                      </Col>
                      <Col span={12}>
                        <Button
                          onClick={() => categoryController.addTag()}
                          disabled={!categoryController.new_tag}
                        >
                          {i.t("def:Add tag")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br/><br/>


              </Col>
            </Row>
        </>
    )
})

const ShopCategoryEditedImage = observer(() => {


    // const updateData = (value) => {
    //     let data = categoryController.category.picture && categoryController.category.picture.length > 0 ? categoryController.category.picture : []
    //     data.push(value)
    //     categoryController.setCategory("picture", data[0])
    // }

    return(
        <>
          <Col span={24}>
            {categoryController.category.image
              ? <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <img src={categoryController.category.image.base + categoryController.category.image.uri} alt='' style={{maxWidth: '250px'}}/>
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={() => {
                      deleteFile(categoryController.category.image.id)
                      categoryController.category.image = null
                    }}
                  >
                    {i.t('def:deletePicture')}
                  </Button>
                </Col>
              </Row>
              : <></>
            }


            <Upload
              beforeUpload={(info) => {
                categoryController.upload(info)
                return false
              }}
              listType="picture-card"
              className="photo-uploader"
              maxCount={1}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
              </div>
            </Upload>
          </Col>
        </>
    )
})

const ShopCategoryEditedTreeSelectButton = observer(({ id }) => {
    return(
        <>
            {
                Number(id) === Number(categoryController.category.parentid)
                    ? <button className="button-main_no-active-little active">{i.t('def:selected')}</button>
                    : <button className="button-main_no-active-little" onClick={() => categoryController.setCategory("parentid", id)}>{i.t('def:select')}</button>
            }
        </>
    )
})

const ShopCategoryEditedTree = observer(({ categoryID }) => {

    return(
        <>
            <List.Item>
                <List.Item.Meta
                    title={<p>{i.t('def:root')}</p>}
                />
                <ShopCategoryEditedTreeSelectButton id={0} parent={0} />
            </List.Item>
            <List
                itemLayout="horizontal"
                dataSource={categoryController.tree}
                renderItem={item => (
                    <>

                        {item.id !== Number(categoryID) &&
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        item.subcategory
                                            ? <p onClick={() => categoryController.getCategoryTree( item.id )}>{ item.name }</p>
                                            : <p>{ item.name }</p>
                                    }
                                />
                                <ShopCategoryEditedTreeSelectButton id={item.id} parent={categoryController.category.parentid} />
                            </List.Item>
                        }
                    </>
                )}
            />
        </>
    )
})

const ShopCategoryEditedFilterSelectButton = observer(({ id }) => {

    return(
        <>
            {
                categoryController.category.filters &&
                categoryController.category.filters.length > 0 &&
                categoryController.category.filters.findIndex(el => Number(el) === Number(id)) >= 0
                    ?
                    <button className="button-main_no-active-little active" onClick={() => categoryController.setCategory("filters", id)}>Снять выбор</button>
                    :
                    <button className="button-main_no-active-little" onClick={() => categoryController.setCategory("filters", id)}>Выбрать</button>
            }
        </>
    )
})

const ShopCategoryEditedFilter = observer(() => {

    return(
        <>
            <List
                itemLayout="horizontal"
                dataSource={pc.properties}
                renderItem={item => (
                    <>
                        <List.Item>
                            <List.Item.Meta
                                title={<p>{ item.name }</p>}
                            />
                            <ShopCategoryEditedFilterSelectButton id={item.id} />
                        </List.Item>
                    </>
                )}
            />
            <br/>
            <Pagination
                current={pc.pagination.page}
                total={pc.pagination.total}
                pageSize={pc.pagination.limit}
                showSizeChanger={false}
                onChange={(e) => pc.setOffset(e)}
            />
        </>
    )
})

const ShopCategoryEdited = observer(() => {

    let { categoryID, createID } = useParams();


    useEffect(() => {
        categoryController.getCategoryByCategoryID(categoryID ? "category" : "create", categoryID ? categoryID : createID)
      categoryController.getCategoryTree()
    }, [])

    return(
        <>

            <TemplateTitle
                title={i.t('category:edit')}
                breadcrumb={categoryController.breadcrumb}
                other={categoryController.category.breadcrumb}
                buttons={[
                    { type: "button", title: i.t('def:actions.save'), ref: (e) => categoryController.postCategory(e), primary: true },
                    { type: "link", title: i.t('def:actions.backList'), link: "/shop/catalog/category/" + categoryController.category.parentid }
                ]}
            />

            <Tabs defaultActiveKey="1" onChange={(e) => categoryController.setTabs(e)}>
                <Tabs.TabPane tab={i.t('def:main')} key="main">
                    <ShopCategoryEditedMain />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i.t('def:image.image')} key="image">
                    <ShopCategoryEditedImage />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i.t('def:filters')} key="filter">
                    <Alert
                        message={i.t('def:attention')}
                        description={i.t('product:propsAttention')}
                        type="warning"
                        closable
                    />
                    <ShopCategoryEditedFilter />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
})

export default ShopCategoryEdited