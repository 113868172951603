import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import {
    Col,
    Input,
    Row,
    Select,
    DatePicker,
    Button,
    Upload,
    notification,
    Switch, Space
} from 'antd'
import pushesController from './pushes.controller'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { storeLocal } from '../../../helpers/stores'
import { ErrorByPricingPlan } from '../../../components/ErrorByPricingPlan'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../../helpers/types.interfaces'
import __select from "../../../helpers/select_entity/__select.controller";
import { SelectEntity } from "../../../helpers/select_entity/SelectEntity";

export const Push = observer(() => {

    const { id = 0 }: any = useParams<ParamTypes>()

    useEffect(() => {
        if(id > 0) pushesController.getOne(id)
    }, [])

    const buttons = [
        { title: i.t('def:actions.back'), href: `/pushes`, type: 'dashed' }
    ]

    return(
        <>
            <Titles
                title={i.t('pushes:push.title')}
                buttons={buttons}
            />

            <form onSubmit={(e) => pushesController.create(e)}>
                <Row>
                    <Col span={24} className="switch-block">
                        <ErrorByPricingPlan
                            content={
                                <div className="element">
                                    <div>
                                        <Switch
                                            size="small"
                                            checked={pushesController.push_type !== 'instant'}
                                            onChange={(e) => pushesController.push_type = e ? 'delayed' : 'instant'}
                                            disabled={!storeLocal.get('pricing_plan') || storeLocal.get('pricing_plan') < 1}
                                        />
                                    </div>
                                    <div>
                                        <span>{i.t(`pushes:push.filter.delayed`)}</span>
                                    </div>
                                </div>
                            }
                            plan={1}
                        />
                    </Col>
                </Row>

                <br/><br/>

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label>{i.t('pushes:push.form.image')}</label>
                        <Space>
                            {pushesController.oldImage && pushesController.oldImage.id
                                ? <>
                                    <img src={pushesController.oldImage.base + pushesController.oldImage.uri} alt='' style={{ width: `100px` }} />
                                    <Button danger onClick={() => pushesController.deletePicture(pushesController.oldImage.id)}>Удалить изображение</Button>
                                </>
                                : <></>}
                            <Upload
                                beforeUpload={(info) => {
                                    if(info.size > 500000) {
                                        notification.error({
                                            message: i.t('def:errors.fileSizeTitle'),
                                            description: i.t('def:errors.fileSizeDescription')
                                        });
                                        return false
                                    }
                                    const isPNG = info.type === 'image/png';
                                    if (!isPNG) {
                                        notification.error({
                                            message: i.t('def:errors.fileTypeTitle'),
                                            description: i.t('def:errors.fileTypeDescription')
                                        });
                                        return false
                                    }
                                    pushesController.upload(info)
                                    return false
                                }}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                                </div>
                            </Upload>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <label>{i.t('pushes:push.form.title')}</label>
                        <Input
                            value={pushesController.push_create.title}
                            onChange={(e) => pushesController.push_create.title = e.target.value}
                            maxLength={50}
                            required
                        />
                    </Col>
                    {(pushesController.push_type === 'instant' || pushesController.push_type === 'delayed') &&
                        <Col span={12}>
                            <label>{i.t('pushes:push.form.devices')}</label>
                            <Select
                                style={{ width: `100%` }}
                                value={pushesController.push_create.devices}
                                onChange={(e) => pushesController.push_create.devices = e}
                            >
                                <Select.Option value="all">{i.t('pushes:push.form.devices_all')}</Select.Option>
                                <Select.Option value="ios">IOS</Select.Option>
                                <Select.Option value="android">Android</Select.Option>
                            </Select>
                        </Col>
                    }
                    <Col span={24}>
                        <label>{i.t('pushes:push.form.description')}</label>
                        <Input.TextArea
                            value={pushesController.push_create.description}
                            onChange={(e) => pushesController.push_create.description = e.target.value}
                            maxLength={150}
                        />
                    </Col>
                    {pushesController.push_type === 'delayed' &&
                        <Col span={10}>
                            <label>{i.t('pushes:push.form.postpone_to')}</label>
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                disabledDate={pushesController.disabledDate}
                                // disabledTime={pushesController.disabledDateTime}
                                showTime={{
                                    format: 'HH',
                                }}
                                value={pushesController.push_create.postpone_to > 0 ? dayjs(pushesController.push_create.postpone_to * 1000) : null}
                                onChange={(e) => {
                                        console.log(e)
                                        console.log(+dayjs(e).format('X'))
                                        pushesController.push_create.postpone_to = +dayjs(e).format('X')
                                    }
                                }
                            />
                        </Col>
                    }
                    {pushesController.push_type !== 'birthday' &&
                        <Col span={24}>
                            {/*<SelectLink*/}
                            {/*    type={pushesController.push_create.element_type}*/}
                            {/*    element={pushesController.push_create.element_id}*/}
                            {/*    item_element={pushesController.push_create.element ?? null}*/}
                            {/*    types={['products']}*/}
                            {/*/>*/}

                            <SelectEntity
                              type={__select.select.type}
                              element={__select.select.value}
                              item_element={__select.select.element}
                            />
                        </Col>
                    }

                    <Col span={24}>
                        <Button type="primary" htmlType="submit">{pushesController.push_type === 'instant' ? i.t('def:actions.send') : i.t('def:actions.save')}</Button>
                    </Col>
                </Row>
            </form>
        </>
    )
})