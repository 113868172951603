import { observer } from "mobx-react-lite"
import controller from './coupon.controller'
import React, { useEffect } from "react";
import i from "../../../translations/i";
import { Badge, Button, Segmented, Space, Table, Tag, Row, Col, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Titles } from "../../../layouts/Titles";
import dayjs from "dayjs"

export const Coupons = observer(() => {

  useEffect(() => {
    controller.getList()
  }, [])

  const buttons = [
    { title: i.t('def:actions.create'), href: `/shop/coupons/0`, type: 'primary' }
  ]

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: i.t('def:active'),
      dataIndex: 'enabled',
      key: 'enabled',
      render: (el: boolean) => el ? <Badge status="success" /> : <Badge status="error" />
    },
    {
      title: i.t('def:Coupon code'),
      dataIndex: 'code',
      key: 'code' ,
      render: (code: string, record: any) => record.color_hex ? <Tag color={record.color_hex !== 'ffffff' ? `#${record.color_hex}` : 'default'}>{code}</Tag> : <Tag>{code}</Tag>
    },
    {
      title: i.t('def:Date start and end'),
      key: 'dates' ,
      render: (record: any) => (
        <>
          <Tag bordered={false} color={record.datestart && record.datestart > 0 && dayjs().unix() < record.datestart ? 'error' : 'success'}>
            {record.datestart && record.datestart > 0 ? dayjs.unix(record.datestart).format("DD.MM.YYYY HH:mm") : i.t('def:Unlimited')}
          </Tag> - <Tag bordered={false} color={record.dateend && record.dateend > 0 && dayjs().unix() > record.dateend ? 'error' : 'success'}>
            {record.dateend && record.dateend > 0 ? dayjs.unix(record.dateend).format("DD.MM.YYYY HH:mm") : i.t('def:Unlimited')}
          </Tag>
        </>
      )
    },
    {
      title: i.t('def:Coupon limit'),
      key: 'countall',
      render: (record: any) => !record.nocount ? `${record.countall} / ${record.count}` : `∞`
    },
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="dashed"
            shape="circle"
            icon={<EditOutlined />}
            href={`/shop/coupons/${record.id}`}
          />
          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.deleteCoupon(record.id)}
          />
        </Space>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Coupons')}
        buttons={buttons}
      />

      <Row gutter={[20, 20]} justify="space-between" align="bottom">
        <Col span={8}>
          <Segmented<string>
            options={[
              { label: i.t("def:Public coupons"), value: 'public' },
              { label: i.t("def:Users coupons"), value: 'users' },
            ]}
            value={controller.segment_type}
            onChange={(value) => controller.onChangeType(value)}
          />
        </Col>
        <Col span={8}>
          <label>{i.t('def:Coupon name')}</label>
          <Input
            value={controller.filter.name ?? ''}
            onChange={(e: any) => controller.onChangeValue('name', e.target.value)}
          />
        </Col>
        <Col span={8}>
          <label>{i.t('def:Coupon code')}</label>
          <Input
            value={controller.filter.code ?? ''}
            onChange={(e: any) => controller.onChangeValue('code', e.target.value)}
          />
        </Col>
      </Row>

      <br/><br/>

      <Table
        rowKey="id"
        columns={columns}
        dataSource={controller.list}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          current: Number(controller.pagination.page),
          pageSize: controller.pagination.limit,
          total: controller.pagination.total,
          showSizeChanger: false,
          onChange: (e) => controller.setPage(e)
        }}
      />
    </>
  )
})