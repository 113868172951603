import { Row, Col, Input } from "antd"
import React from "react"

interface FilterHeaderInterface {
  fields: FilterHeaderFieldInterface[]
}

interface FilterHeaderFieldInterface {
  label: string
  value: string | number | null
  type: string
  onChange: any
}

export const FilterHeader = ({ fields }: FilterHeaderInterface) => {
  return(
    <div className="filter-header">
      <Row gutter={[15, 15]}>
        {fields.map((field: FilterHeaderFieldInterface) => <FilterField field={field} />)}
      </Row>
    </div>
  )
}

const FilterField = ({ field }: { field: FilterHeaderFieldInterface }) => {
  return(
    <Col>
      <label>{field.label}</label>
      {field.type === 'string'
        ? <>
          <Input
            value={field.value ?? ''}
            onChange={(e: any) => field.onChange(e.target.value)}
          />
        </>
        : <></>
      }
    </Col>
  )
}