import {openApi, request} from '../../../helpers/request'
import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { notification } from 'antd'

export class WarehousesController {

    list: any = []
    element:any = {

    }
    pagination = { total: 0, limit: 10, page: 1, offset: 0 }

    zones = []
    places = []

    constructor() {
        makeAutoObservable(this)
    }

    getZones() {
        openApi('get', "/setting/delivery_zone")
            .then(success => {
                this.zones = success.data
            })
            .catch(error => console.log(error));
    }

    getPlaces() {

        openApi('get', `/places?limit=100000000`)
            .then((result) => {
                this.places = result.data
            })
    }

    setPage(page: number) {
        this.pagination.page = page
        this.get()
    }

    getElement(id: any) {
        openApi('get', `/warehouses/${id}`)
            .then(result => {
                console.log(result)
                result = result.data
                this.element = result

                let zones: any[] = []
                result.zones.length > 0 && result.zones.map((el: any) => zones.push(el.id))
                this.element.zones = zones

                let places: any[] = []
                result.places.length > 0 && result.places.map((el: any) => places.push(el.id))
                this.element.places = places
            })
    }

    get(limit: null | Number = null) {
        let _limit = limit ? limit : this.pagination.limit
        openApi('get', `/warehouses?limit=${_limit}&page=${this.pagination.page}`)
            .then(result => {
                console.log(result)
                result = result.data
                this.pagination.total = result.size
                this.pagination.page = result.page
                this.pagination.limit = result.limit
                this.list = result.items
            })
    }

    onChange(name: string, value: any) {
        if(name === 'coordinates') {
            this.element.latitude = value[0]
            this.element.longitude = value[1]
        } else if(name === 'zone') {
            this.element.zones = value
        } else if(name === 'place') {
            this.element.places = value
        } else {
            this.element[name] = value
        }
    }

    save(e: FormEvent) {
        e.preventDefault()

        const path = this.element.id && this.element.id > 0 ? `/warehouses/${this.element.id}` : `/warehouses`

        const data = new FormData()
        data.append('name', this.element.name)
        data.append('city_id', this.element.city_id ?? null)
        data.append('latitude', this.element.latitude ?? 0)
        data.append('longitude', this.element.longitude ?? 0)
        data.append('picture', this.element.picture)
        if(this.element.id_crm) data.append('id_crm', this.element.id_crm)


        this.element.zones && this.element.zones.length > 0 && this.element.zones.map((el:any) => data.append('deliveryzoneid[]', el))
        this.element.places && this.element.places.length > 0 && this.element.places.map((el:any) => data.append('place_id[]', el))

        openApi('post', path, data)
            .then(result => {
                if(!this.element.id || this.element.id === 0) window.location.href = `/settings/warehouses`
                else {
                    this.getElement(this.element.id)
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.edit')
                    });
                }
            })
            .catch(err => {
                console.log(err.response)
            })

    }

    remove(id: any) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/warehouses/${id}`)
                .then(success => {
                    this.get()
                })
        }
    }

    upload(image: any) {
        this.element.picture = image
        return false
    }

    removeImage(id: string | number) {
        if(window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/warehouses/picture/${id}`)
                .then(result => {
                    this.getElement(id)
                })
        }
    }
}