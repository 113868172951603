import { makeAutoObservable } from 'mobx'
import { openApi } from "../../../helpers/request";
import { FormEvent } from "react";
import i from "../../../translations/i";
import { notification } from "antd";

class QuantityControlController {

  list: any[] = []
  item: any = {
    id: null,
    from: null,
    to: null,
    label: null,
    color_hex: '000000',
    view_count: false,
    none: false,
  }

  constructor() {
    makeAutoObservable(this)
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    if(this.item.label) data.append('label', this.item.label)
    if(this.item.from) data.append('from', `${this.item.from}`)
    if(this.item.to) data.append('to', `${this.item.to}`)
    data.append('color_hex', this.item.color_hex)
    data.append('view_count', `${+ this.item.view_count}`)
    data.append('none', `${+ this.item.none}`)

    let uri = `/quantity_control/`

    if(this.item.id && this.item.id > 0)
      uri += this.item.id

    openApi('post', uri, data)
      .then((result: any) => {
        console.log(result)
        if(this.item.id && this.item.id > 0)
          window.location.reload()
        else window.location.href = `/settings/quantity_control`
      })
      .catch(error => {
        console.log(error.response);
        notification.error({
          message: i.t('def:errors.error'),
          description: error.response.data.data.message
        })
      })
  }

  getList() {
    openApi('get', `/quantity_control`)
      .then((result: any) => {
        console.log(result)
        this.list = result.data
      })
      .catch(error => console.log(error.response))
  }

  getOne(id: any) {
    if(id && id > 0) {
      openApi('get', `/quantity_control/${id}`)
        .then((result: any) => {
          console.log(result)
          this.item = result.data

        })
        .catch(error => console.log(error.response))
    }
  }

  destroy(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/quantity_control/${id}`)
        .then((result: any) => {
          window.location.reload()
        })
        .catch(error => console.log(error.response))
  }

}

export default new QuantityControlController()