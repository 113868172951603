import { makeAutoObservable } from "mobx";
import { openApi } from "../../../helpers/request";
import { FormEvent } from "react";

class DiscountController {

  pagination = {
    total: 0,
    page: 1,
    limit: 10
  }

  list: any[] = []

  item: any = {
    id: 0,
    name: '',
    enabled: true,
    amount: 0,
    measurement: 0,
    purpose: null,
    id_crm: null,
    apply_old_price: true
  }

  constructor() {
    makeAutoObservable(this)
  }

  getList() {
    openApi('get', '/discounts')
      .then(result => {
        console.log(result.data)
        this.list = result.data
      })
  }

  getOne(id: any) {
    if(id > 0)
      openApi('get', `/discounts/${id}`)
        .then(result => {
          console.log(result.data)
          this.item = result.data
        })
  }

  delete(id: any) {
    openApi('delete', `/discounts/${id}`)
      .then(result => {
        this.getList()
      })
      .catch(error => {

      })
  }

  save(e: FormEvent) {
    e.preventDefault()

    const data = new FormData()
    data.append('name', this.item.name)
    data.append('enabled', `${+ this.item.enabled}`)
    data.append('amount', `${this.item.amount}`)
    data.append('measurement', `${this.item.measurement}`)
    data.append('purpose', this.item.purpose)
    if(this.item.id_crm) data.append('id_crm', this.item.id_crm)

    data.append('apply_old_price', `${+ this.item.apply_old_price}`)

    openApi('post', `/discounts/${this.item.id}`, data)
      .then(result => {
        window.location.href = `/settings/discount`
      })
      .catch(error => {

      })
  }

}

export default new DiscountController()