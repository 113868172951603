import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import __catalog from './__catalog'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { ProductsList } from '../../components/list/ProductsList'
import { IProduct } from '../../components/list/types'
import { Row, Button, Image, Space, Alert, Table, Tooltip, Switch, Col, Segmented, Select } from "antd";
import { DownOutlined, SettingOutlined, UnorderedListOutlined, UpOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { WarehousesController } from "../settings/warehouses/warehouses.controller";

const Warehouses = new WarehousesController()

export const CatalogProductList = observer(() => {

  let { categoryID = 0 }: any = useParams()

  useEffect(() => {
    return () => {
      Warehouses.get(1000000)
      runInAction(() => __catalog.this_category_id = Number(categoryID))
      __catalog.getProducts()
    }
  }, [])

  return(
    <>
      <Titles
        title={i.t('categories:catalog')}
        breadcrumb={__catalog.tree}
        buttons={[
          { title: i.t('categories:createCategory'), href: `/shop/catalog/category/create/${__catalog.this_category_id}`, type: 'dashed' },
          { title: i.t('categories:createProduct'), href: `/shop/catalog/product/0/${__catalog.this_category_id}`, type: 'dashed' },
          { title: i.t('categories:prices'), href: `/shop/mass`, type: 'dashed' }
        ]}
      />

      {__catalog.productWithoutCategory > 0 &&
        <>
          <Alert
            message={`${i.t('categories:without')}: ${__catalog.productWithoutCategory}`}
            type="info"
            action={
              <Space direction="vertical">
                <Button size="small" type="primary" href="/shop/without">
                  {i.t('categories:withoutList')}
                </Button>
              </Space>
            }
            closable
          />
          <br/>
        </>
      }

      <Row>
        <Col span={12}>
          <Segmented
            value={__catalog.filter.segmented}
            options={[
              { label: i.t('def:All'), value: 'all' },
              { label: i.t('def:Active'), value: "active" },
              { label: i.t('def:Hidden'), value: "hidden" },
            ]}
            onChange={(e) => {
              __catalog.filter.segmented = e
              __catalog.page = 1
              __catalog.getProducts()
            }}
          />
        </Col>
        <Col span={12}>
          <Select
            placeholder={i.t('def:Select warehouse')}
            value={__catalog.filter.warehouse_id}
            options={[
              { label: i.t('def:Select warehouse'), value: null },
              ...Warehouses.list.map((el: any) => ({
                label: el.name,
                value: el.id
              }))
            ]}
            onChange={(e) => {
              __catalog.filter.warehouse_id = e
              __catalog.page = 1
              __catalog.getProducts()
            }}
            style={{ width: `100%` }}
          />
        </Col>
      </Row>



      <br/>

      <ProductsList
        products={__catalog.list_products}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          current: __catalog.page,
          pageSize: __catalog.limit,
          total: __catalog.total,
          showSizeChanger: false,
          onChange: (e: number) => __catalog.setPageProducts(e)
        }}
        actions={{
          title: i.t('orders:actions'),
          key: 'action',
          align: 'right',
          render: (record: IProduct) => (
            <Space>
              <Button
                target="_blank"
                type="dashed"
                icon={<ArrowUpOutlined />}
                onClick={() => __catalog.setPositionProduct(record.id, 'up')}
              />
              <Button
                target="_blank"
                type="dashed"
                icon={<ArrowDownOutlined />}
                onClick={() => __catalog.setPositionProduct(record.id, 'down')}
              />
              <Button
                href={`/shop/catalog/product/${record.id}`}
                target="_blank"
                type="dashed"
                icon={<SettingOutlined />}
              />
            </Space>
          )
        }}
        setActive={(productID: number, view: boolean) => __catalog.setActiveProduct(productID, view)}
      />
    </>
  )
})