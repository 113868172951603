import { observer } from "mobx-react-lite";
import PaymentGatewayController from "./payment.gateway.controller";
import React, { FormEvent, useEffect } from "react";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import {
  Badge,
  Button,
  Col,
  Divider,
  Input,
  Select,
  Row,
  Segmented,
  Space,
  Switch,
  Table,
  Tooltip,
  Upload
} from "antd";
import { useParams } from "react-router-dom";
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import PaymentTypeController from "./payment.type.controller";
import { SwitchBlock } from "../../../components/SwitchBlock";

export const PaymentGateway = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    PaymentGatewayController.getOne(id)
  }, [])

  return(
    <>
      <form onSubmit={(e: FormEvent) => PaymentGatewayController.save(e)}>
        <Titles
          title={i.t('def:Payment gateway')}
          buttons={[
            { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings/payment_gateway` },
            { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' }
          ]}
          breadcrumb={[
            {title: i.t('def:settings'), href: '/settings'},
            { title: i.t('def:Payment gateway list'), href: '/settings/payment_gateway' },
            { title: i.t('def:Payment gateway') },
          ]}
        />

        <Divider />

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <label className="formLabel">{i.t('def:name')}</label>
            <Input
              name="title"
              placeholder={i.t('def:name')}
              value={PaymentGatewayController.item.label}
              onChange={(e) => PaymentGatewayController.item.label = e.target.value}
              required
            />
          </Col>
          <Divider />
          <Col span={12}>
            <label className="formLabel">{i.t('def:Yookassa shop_id')}</label>
            <Input
              placeholder={i.t('def:Yookassa shop_id')}
              value={PaymentGatewayController.item.shop_id}
              onChange={(e) => PaymentGatewayController.item.shop_id = e.target.value}
              required
            />
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:Yookassa secret_key')}</label>
            <Input
              placeholder={i.t('def:Yookassa secret_key')}
              value={PaymentGatewayController.item.secret_key}
              onChange={(e) => PaymentGatewayController.item.secret_key = e.target.value}
              required
            />
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:Yookassa gateway_id')}</label>
            <Input
              placeholder={i.t('def:Yookassa gateway_id')}
              value={PaymentGatewayController.item.gateway_id}
              onChange={(e) => PaymentGatewayController.item.gateway_id = e.target.value}
              required
            />
          </Col>
          <Divider />
          <Col span={12}>
            <label className="formLabel">{i.t('def:Yookassa tax_system_code')}</label>
            <Select
              value={Number(PaymentGatewayController.item.tax_system_code)}
              options={[
                { label: 'Общая система налогообложения', value: 1 },
                { label: 'Упрощенная (УСН, доходы)', value: 2 },
                { label: 'Упрощенная (УСН, доходы минус расходы)', value: 3 },
                { label: 'Единый налог на вмененный доход (ЕНВД)', value: 4 },
                { label: 'Единый сельскохозяйственный налог (ЕСН)', value: 5 },
                { label: 'Патентная система налогообложения', value: 6 },
              ]}
              onChange={(e: any) => PaymentGatewayController.item.tax_system_code = e}
              style={{width: `100%`}}
            />
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:Yookassa vat_code')}</label>
            <Select
              value={Number(PaymentGatewayController.item.vat_code)}
              options={[
                { label: 'Без НДС', value: 1 },
                { label: 'НДС по ставке 0%', value: 2 },
                { label: 'НДС по ставке 10%', value: 3 },
                { label: 'НДС чека по ставке 20%', value: 4 },
                { label: 'НДС чека по расчетной ставке 10/110', value: 5 },
                { label: 'НДС чека по расчетной ставке 20/120', value: 6 },
              ]}
              onChange={(e: any) => PaymentGatewayController.item.vat_code = e}
              style={{width: `100%`}}
            />
          </Col>
          <Divider />
          <Col xs={24}>
            <SwitchBlock array={[
              { label: i.t('def:Yookassa card_online'), click: (e: any) => PaymentGatewayController.item.card_online = e, value: PaymentGatewayController.item.card_online },
              { label: i.t('def:Yookassa sberpay'), click: (e: any) => PaymentGatewayController.item.sberpay = e, value: PaymentGatewayController.item.sberpay },
              { label: i.t('def:Yookassa mirpay'), click: (e: any) => PaymentGatewayController.item.mirpay = e, value: PaymentGatewayController.item.mirpay },
              { label: i.t('def:Yookassa save_card'), click: (e: any) => PaymentGatewayController.item.save_card = e, value: PaymentGatewayController.item.save_card },
              { label: i.t('def:Yookassa sbp'), click: (e: any) => PaymentGatewayController.item.sbp = e, value: PaymentGatewayController.item.sbp, block: 2 },
              { label: i.t('def:Yookassa tinkoff_pay'), click: (e: any) => PaymentGatewayController.item.tinkoff_pay = e, value: PaymentGatewayController.item.tinkoff_pay },
            ]} />
          </Col>
        </Row>
      </form>
    </>
  )
})

export const PaymentGatewayList = observer(() => {

  useEffect(() => {
    PaymentGatewayController.getList()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('def:Label'),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: i.t('def:Yookassa shop_id'),
      dataIndex: 'shop_id',
      key: 'shop_id'
    },
    {
      title: i.t('orders:actions'),
      key: 'action',
      render: (item: any) => (
        <>
          <Space>
            <Button
              type="dashed"
              href={`/settings/payment_gateway/${item.id}`}
              icon={<EditOutlined />}
              shape="circle"
            />

            <Button
              type="dashed"
              onClick={() => PaymentGatewayController.destroy(item.id)}
              icon={<DeleteOutlined />}
              shape="circle"
              danger
            />
          </Space>
        </>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Payment gateway list')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/settings/payment_gateway/0` }
        ]}
        breadcrumb={[
          {title: i.t('def:settings'), href: '/settings'},
          { title: i.t('def:Payment gateway list') },
        ]}
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={PaymentGatewayController.list}
        pagination={false}
      />

    </>
  )
})