import { observer } from "mobx-react-lite";
import QuantityControlController from './qunatity_control.controller'
import React, { FormEvent, useEffect } from "react";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  InputNumber,
  Table,
} from "antd";
import { useParams } from "react-router-dom";
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import { SwitchBlock } from "../../../components/SwitchBlock";
import { SketchPicker } from "react-color";
import { runInAction } from "mobx"

export const QuantityControl = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    QuantityControlController.getOne(id)
  }, [])

  return(
    <>
      <form onSubmit={(e: FormEvent) => QuantityControlController.save(e)}>
        <Titles
          title={i.t('def:Quantity control')}
          buttons={[
            { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings/quantity_control` },
            { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' }
          ]}
          breadcrumb={[
            {title: i.t('def:settings'), href: '/settings'},
            { title: i.t('def:Quantity control list'), href: '/settings/quantity_control' },
            { title: i.t('def:Quantity control') },
          ]}
        />

        <Divider />

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <SwitchBlock array={[
              { label: i.t('def:View count'), click: (e: any) => QuantityControlController.item.view_count =  e, value: QuantityControlController.item.view_count },
              { label: i.t('def:None'), click: (e: any) => QuantityControlController.item.none =  e, value: QuantityControlController.item.none },
            ]} />
          </Col>
          {QuantityControlController.item.view_count || QuantityControlController.item.none
            ? <></>
            : <Col span={8}>
              <label className="formLabel">{i.t('def:name')}</label>
              <Input
                name="title"
                placeholder={i.t('def:name')}
                value={QuantityControlController.item.label}
                onChange={(e) => QuantityControlController.item.label = e.target.value}
                required
              />
            </Col>
          }

          <Col span={8}>
            <label className="formLabel">{i.t('def:count_from')}</label>
            <InputNumber
              placeholder={i.t('def:count_from')}
              value={QuantityControlController.item.from}
              onChange={(e) => QuantityControlController.item.from = e}
              style={{ width: '100%' }}
              required
            />
          </Col>
          <Col span={8}>
            <label className="formLabel">{i.t('def:count_to')}</label>
            <InputNumber
              placeholder={i.t('def:count_to')}
              value={QuantityControlController.item.to}
              onChange={(e) => QuantityControlController.item.to = e}
              style={{ width: '100%' }}
              required
            />
          </Col>

          {QuantityControlController.item.none
            ? <></>
            : <>
              <Col span={24}>
                <label className="formLabel">{i.t('orders:selectColor')}</label>
                <SketchPicker
                  color={`#${QuantityControlController.item.color_hex}`}
                  onChange={(e) => runInAction(() => QuantityControlController.item.color_hex = (e.hex).replace('#', ''))}
                />
              </Col>
            </>
          }

        </Row>
      </form>
    </>
  )
})

export const QuantityControlList = observer(() => {

  useEffect(() => {
    QuantityControlController.getList()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('def:Label'),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: i.t('def:count_from'),
      dataIndex: 'from',
      key: 'from'
    },
    {
      title: i.t('def:count_from'),
      dataIndex: 'to',
      key: 'to'
    },
    {
      title: i.t('orders:actions'),
      key: 'action',
      render: (item: any) => (
        <>
          <Space>
            <Button
              type="dashed"
              href={`/settings/quantity_control/${item.id}`}
              icon={<EditOutlined />}
              shape="circle"
            />

            <Button
              type="dashed"
              onClick={() => QuantityControlController.destroy(item.id)}
              icon={<DeleteOutlined />}
              shape="circle"
              danger
            />
          </Space>
        </>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Quantity control list')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/settings/quantity_control/0` }
        ]}
        breadcrumb={[
          {title: i.t('def:settings'), href: '/settings'},
          { title: i.t('def:Quantity control list') },
        ]}
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={QuantityControlController.list}
        pagination={false}
      />

    </>
  )
})