import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, Col, Input, Row, Space, Switch, Table, Tooltip, Upload } from "antd";
import { DeleteOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import React, { FormEvent, useEffect } from "react";
import controller from "./news.types.controller";
import { useParams } from "react-router-dom";
import { runInAction } from "mobx";
import { SketchPicker } from "react-color";

export const NewsTypes = observer(() => {

  useEffect(() => {
    return () => {
      controller.getList()
    }
  }, [])

  const columns = [
    {
      title: i.t('def:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('def:actions.actions'),
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title={i.t('def:actions.edit')}>
            <Button type="dashed" shape="circle" href={`/news_types/${record.id}`} icon={<SettingOutlined />} />
          </Tooltip>

          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.delete(record.id)}
          />
        </Space>
      ),
    },
  ]

  return(
    <>
      <Titles
        title={i.t('def:News Types')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/news_types/0` }
        ]}
        breadcrumb={[
          {title: i.t('def:News Types')},
        ]}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})

export const NewsType = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    return () => {
      controller.getOne(id)
    }
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/news_types`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return(
    <>
      <form onSubmit={(e: FormEvent) => controller.save(e)}>
        <Titles
          title={i.t('def:News type')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col xs={24} md={24}>
            <label>{i.t('settings:cities.form.name')}</label>
            <Input
              name="title"
              placeholder={i.t('settings:cities.form.name')}
              value={controller.item.name}
              onChange={(e) => runInAction(() => controller.item.name = e.target.value)}
            />
          </Col>
          <Col xs={24} md={24}>
            <label>{i.t('def:Select color')}</label>
            <SketchPicker
              color={`#${controller.item.color_hex}`}
              onChange={(e) => runInAction(() => controller.item.color_hex = (e.hex).replace('#', ''))}
            />
          </Col>
        </Row>
      </form>
    </>
  )
})