import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, Col, Divider, Dropdown, Image, Input, Row, Segmented, Space, Switch, Table, Tooltip } from "antd";
import { DeleteOutlined, DownOutlined, SettingOutlined } from "@ant-design/icons";
import React, { FormEvent, useEffect } from "react";
import controller from "./news.controller";
import { useParams } from "react-router-dom"
import { NewsModuleMain } from "./element/modules/NewsModuleMain"
import { NewsModuleText } from "./element/modules/NewsModuleText";
import { NewsModuleShort } from "./element/modules/NewsModuleShort";
import { NewsModuleProps } from "./element/modules/NewsModuleProps";

export const News = observer(() => {

  useEffect(() => {
    return () => {
      controller.getList()
    }
  }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('def:image.image'),
      dataIndex: 'image',
      key: 'image',
      render: (image: any) => image ? <Image width={'100px'} src={`${image.base}${image.uri}`} /> : <></>,
    },
    {
      title: i.t('def:status'),
      key: 'enabled',
      dataIndex: 'enabled',
      render: (enabled: number) => enabled ? i.t('def:yes') : i.t('def:no')
    },
    {
      title: i.t('def:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('def:actions.actions'),
      key: 'action',
      render: (record: any) => (
        <Space>
          <Tooltip title={i.t('def:actions.edit')}>
            <Button type="dashed" shape="circle" href={"/news/" + record.id} icon={<SettingOutlined />} />
          </Tooltip>
          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.delete(record.id)}
          />
        </Space>
      ),
    },
  ]

  return(
    <>
      <Titles
        title={i.t('def:News list')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/news/create` }
        ]}
        breadcrumb={[
          {title: i.t('def:News list')},
        ]}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          current: Number(controller.pagination.page),
          pageSize: controller.pagination.limit,
          total: controller.pagination.total,
          showSizeChanger: false,
          onChange: (e) => controller.setPage(e)
        }}
      />
    </>
  )
})

export const NewsElement = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    return () => {
      controller.getOne(id)

    }
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/news`, type: 'dashed' },
    { title: i.t('def:actions.save'), type: 'primary', click: () => controller.save() }
  ]

  return(
    <>
      <Titles
        title={i.t('def:News element')}
        buttons={buttons}
      />

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Segmented
            options={[
              { label: i.t('def:segmented.main'), value: 'main' },
              { label: i.t('def:segmented.short'), value: 'short' },
              { label: i.t('def:segmented.text'), value: 'text' },
              { label: i.t('def:segmented.props'), value: 'props' }
            ]}
            onChange={(e) => controller.changeTab(e)}
            value={controller.item_tab}
          />
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>
          {controller.item_tab === 'main' ? <NewsModuleMain /> : <></>}
          {controller.item_tab === 'short' ? <NewsModuleShort /> : <></>}
          {controller.item_tab === 'text' ? <NewsModuleText />: <></>}
          {controller.item_tab === 'props' ? <NewsModuleProps />: <></>}
        </Col>
      </Row>
    </>
  )
})